import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { RemoteData, NotAuthorized } from 'rf-comp';
import PropTypes from 'prop-types';
import {
  H1, Page, FormField, Button, TransactionList
} from 'rf-ui';
import { Formik, Form } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';
import { get, isAuthorized } from 'rf-lib';
import BalanceContainer from './Balance';
import * as a from './actions';
import * as g from '../playergroups/playerGroupInfo/actions';

const Container = styled.div`
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 10px;
  grid-gap: 20px;
  align-items: start;
`;

const SContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: ${get('spacingSm')} 0 ${get('spacingMd')} 0;
`;

const Select = styled.select`
  display: block;
  font-size: 16px;
  border-radius: 3px;
  padding: 0.6rem;
  height: auto;
`;

const validationSchema =
  Yup.object({
    playerID: Yup.string(),
    amount: Yup.number()
      .typeError('Beløpet må være et tall')
      .required('Beløp må være fylt ut')
      .min(-10000, 'Beløp kan ikke være mindre enn -10 000')
      .max(10000, 'Beløp kan ikke være høyere enn 10 000')
      .notOneOf([0], 'Beløp kan ikke være 0')
  });

const RegisterBox = ({ groupId, authUser, ...props }) => {
  const [user, setUser] = useState();

  useEffect(() => {
    props.fetchPlayerTransactions(groupId, authUser.uid);
  }, []);

  const handlePlayerChange = event => {
    setUser(event.target.value);
    const playerId = event.target.value;
    props.formikValues.setFieldValue('playerID', playerId);
    props.resetPlayerTransactionState();
    props.fetchPlayerTransactions(groupId, playerId);
  };

  return (
    <div>
      <Container>
        <SContainer>
          <p>Spiller</p>
          <Select name="playerID" onChange={handlePlayerChange} defaultValue={authUser.uid} {...props}>
            {
              props.players
                .sort((x, y) => x.email.localeCompare(y.email))
                .map(player => ({ id: player.id, title: `${player.displayName} (${player.email})` }))
                .map((item, key) => (
                  <option key={key} value={item.id}>
                    {item.title}
                  </option>
                ))
            }
          </Select>
        </SContainer>
        <SContainer style={{ margin: 0 }}>
          <FormField
            name="amount"
            type="text"
            title="Beløp"
          />
        </SContainer>
        <BalanceContainer.Balance groupId={groupId} userUid={user} />
        <Container>
          <SContainer style={{ marginTop: '1.5rem', flexDirection: 'row' }}>
            <Button title="Lagre" type="submit" />
          </SContainer>
        </Container>
      </Container>
      <RemoteData
        type={props.transactionState}
        loading={() => <span>Henter...</span>}
        failure={err => <span>Feil: {err.message}</span>}
        success={balance => (
          <TransactionList
            transactions={balance}
            totalTransactionsShown={20}
          />
        )}
      />
    </div>
  );
};

RegisterBox.propTypes = {
  authUser: PropTypes.object,
  groupId: PropTypes.string.isRequired,
  fetchPlayerTransactions: PropTypes.func,
  transactionState: PropTypes.object.isRequired,
  resetPlayerTransactionState: PropTypes.func.isRequired,
  formikValues: PropTypes.object.isRequired,
  players: PropTypes.array
};

const RegisterPayment = ({ authUser, ...props }) => {
  const { groupId } = useParams();

  if (!isAuthorized('accountant', groupId, authUser)) {
    return <NotAuthorized message="You are not authorized to register payments in this group" />;
  }

  useEffect(() => {
    props.fetchCurrentGroup(groupId);
    props.resetPlayerTransactionState();
    props.fetchGroupPlayers(groupId);
  }, []);

  const initialValues = {
    playerID: authUser.uid,
    amount: 1
  };

  return (
    <Page>
      <H1>Innbetalinger for {props.groupName}</H1>
      <Formik initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        onSubmit={values => {
          props.onCreateTransaction(
            groupId,
            values.playerID,
            values.amount,
            'payment',
            authUser.uid
          );
          props.resetPlayerTransactionState();
          props.fetchPlayerTransactions(groupId, values.playerID);
        }}
        validateOnBlur={false}
      >{formikValues => (
        <Form>
          <RemoteData
            type={props.playersState}
            loading={() => <span>Henter...</span>}
            failure={err => <span>Feil: {err.message}</span>}
            success={players => (
              <RegisterBox
                groupId={groupId}
                players={players}
                fetchPlayerTransactions={props.fetchPlayerTransactions}
                transactionState={props.transactionState}
                resetPlayerTransactionState={props.resetPlayerTransactionState}
                formikValues={formikValues}
                authUser={authUser}
              />
            )}
          />
        </Form>
      )}
      </Formik>
    </Page>
  );
};

RegisterPayment.propTypes = {
  authUser: PropTypes.object,
  groupName: PropTypes.string.isRequired,
  fetchPlayerTransactions: PropTypes.func.isRequired,
  fetchGroupPlayers: PropTypes.func.isRequired,
  fetchCurrentGroup: PropTypes.func.isRequired,
  resetPlayerTransactionState: PropTypes.func.isRequired,
  onCreateTransaction: PropTypes.func.isRequired,
  transactionState: PropTypes.object.isRequired,
  playersState: PropTypes.object.isRequired,
};

const getGroupName = playerGroupInfo => {
  if (playerGroupInfo
    && playerGroupInfo.groupDetailsState
    && playerGroupInfo.groupDetailsState.data) {
    return playerGroupInfo.groupDetailsState.data.groupName;
  }

  return '...';
};

const mapStateToProps = ({ session, balance, playerGroupInfo }) => ({
  authUser: session.authUser,
  transactionState: balance.transactionState,
  createTransactionState: balance.createTransactionState,
  playersState: balance.playersState,
  groupName: getGroupName(playerGroupInfo)
});

const mapDispatchToProps = dispatch => ({
  fetchPlayerTransactions: a.fetchPlayerTransactions(dispatch),
  fetchGroupPlayers: a.fetchPlayersInCurrentGroup(dispatch),
  fetchCurrentGroup: g.getGroupDetails(dispatch),
  onCreateTransaction: a.onCreateTransaction(dispatch),
  resetPlayerTransactionState: a.resetPlayerTransactionState(dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterPayment);
