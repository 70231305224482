import { curry } from 'ramda';

// NOTE: Use with styled-components to easily switch between versions of component
const variant = curry(
  (variants, props) => {
    const appliedVariant = variants[props.variant];

    if (!appliedVariant) {
      /* eslint-disable-next-line */
      console.error(`Unsupported variant ${props.variant} supplied to ${JSON.stringify(variants)}`);
    }

    return appliedVariant;
  }
);

export default variant;
