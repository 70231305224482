import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { RemoteData, NotAuthorized } from 'rf-comp';
import {
  Page, Text, TextSmall, H1, Bar, Button
} from 'rf-ui';
import { get, isAuthorized } from 'rf-lib';
import { People, Delete, Edit } from 'styled-icons/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import BalanceContainer from '../../balance/Balance';
import * as a from './actions';

const GroupContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: justify-content;
  justify-content: space-between;
`;

const GroupDetailsContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

const ListContainer = styled.div`
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  margin: ${get('spacingSm')};
  padding: ${get('spacingSm')};
  border: 1px solid rgba(255, 255, 255, 0.5);
`;

const ListItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 2px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: ${get('spacingMd')};
`;

const InvitationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: ${get('spacingMd')};
`;

const RowContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 3;
  padding: 3;
`;

const Invitation = ({ groupId }) => (
  <InvitationContainer>
    <Text>
      Inviter vennene eller kollegene dine til gruppen ved å sende dem denne
      invitasjonslenken:
    </Text>
    <Bar>
      <TextSmall>
        {`${window.location.origin}/#/groups/invite/${groupId}`}{' '}
      </TextSmall>
      <CopyToClipboard
        text={`${window.location.origin}/#/groups/invite/${groupId}`}
      >
        <Button title="Kopier lenke" />
      </CopyToClipboard>
    </Bar>
  </InvitationContainer>
);

Invitation.propTypes = {
  groupId: PropTypes.string.isRequired,
};

const Players = ({
  userIsAdmin, userId, players, groupId, removeMember
}) => {
  const playerRoles = player => {
    if (!player.roles) return '';

    const authorized = Object.keys(player.roles || {})
      .filter(key => player.roles[key]===true)
      .flatMap(key => key);

    if (!authorized || authorized.length === 0) return '';
    return `(${authorized.join()})`;
  };

  const history = useHistory();

  const editAuthorizations = (event, playerId) => {
    history.push(`/groups/authorizations/${groupId}/${playerId}`);
    event.preventDefault();
  };

  return (
    <ListContainer>
      <Text>Gruppemedlemmer</Text>
      {players?.sort((x, y) => x.displayName - y.displayName)
        .map(player => (
          <ListItem key={player.id}>
            <TextSmall>{`${player.displayName}
              ${userIsAdmin
              ? ` : ${player.email} ${playerRoles(player)}`
              : ''} 
              `}
            </TextSmall>
            { (userIsAdmin && !(player.id === userId)) && (
              <Delete style={{ cursor: 'pointer' }} size="1rem" onClick={() => removeMember(groupId, player.id)} />
            )}
            { (userIsAdmin && (
              <Edit style={{ cursor: 'pointer' }} size="1rem" onClick={event => editAuthorizations(event, player.id)} />
            ))}
          </ListItem>
        ))}
    </ListContainer>
  );
};

Players.propTypes = {
  players: PropTypes.array.isRequired,
  groupId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  userIsAdmin: PropTypes.bool.isRequired,
  removeMember: PropTypes.func.isRequired,
};

const Games = ({
  userIsAdmin, games, groupId, removeGame
}) => (
  <ListContainer>
    <Text>Spill</Text>
    {games?.map(game => (
      <ListItem key={game.id}>
        <TextSmall>{`${game.gameName}: max tickets = ${game.maxTickets}`}</TextSmall>
        { (userIsAdmin) && (
          <Delete style={{ cursor: 'pointer' }} size="1rem" onClick={() => removeGame(groupId, game.id)} />
        )}
      </ListItem>
    ))}
  </ListContainer>
);

Games.propTypes = {
  userIsAdmin: PropTypes.bool.isRequired,
  games: PropTypes.array.isRequired,
  groupId: PropTypes.string.isRequired,
  removeGame: PropTypes.func.isRequired,
};

const GroupDetails = ({
  authUser, group, removeMember, removeGame
}) => {
  const userIsAdmin = isAuthorized('admin', group.id, authUser);

  return (
    <GroupContainer>
      <RowContainer>
        <H1>{group.groupName}</H1>
        <BalanceContainer.Balance groupId={group.id} userUid={authUser.uid} />
      </RowContainer>
      <GroupDetailsContainer>
        {group.imageUrl ? (
          <img src={group.imageUrl} alt="img" height="100" />
        ) : (
          <People size="2rem" />
        )}
        <TextContainer>
          <Text> {group.groupDescription} </Text>
        </TextContainer>
      </GroupDetailsContainer>
      {userIsAdmin && <Invitation groupId={group.id} />}

      <RowContainer>
        <Players
          userIsAdmin={userIsAdmin}
          userId={authUser.uid}
          players={group.players}
          groupId={group.id}
          removeMember={removeMember} />
        <Games
          userIsAdmin={userIsAdmin}
          games={group.games}
          groupId={group.id}
          removeGame={removeGame} />
      </RowContainer>
    </GroupContainer>
  );
};

GroupDetails.propTypes = {
  group: PropTypes.object.isRequired,
  authUser: PropTypes.object.isRequired,
  removeMember: PropTypes.func.isRequired,
  removeGame: PropTypes.func.isRequired,
};

const GroupInfo = ({ authUser, ...props }) => {
  const { id } = useParams();

  if (!isAuthorized('player', id, authUser)) {
    return <NotAuthorized message="You are not authorized to view this player group" />;
  }

  const history = useHistory();

  useEffect(() => {
    props.fetchGroupDetails(id);
  }, []);

  return (
    <Page>
      <RemoteData
          type={props.groupDetailsState}
          loading={() => <H1>Henter gruppedetaljer...</H1>}
          failure={err => <span>Feil: {err.message}</span>}
          success={group => (
            <GroupDetails
              authUser={authUser}
              group={group}
              removeMember={props.removeMember}
              removeGame={props.removeGame} />
          )}
      />
      <Button onClick={() => history.push('/groups/')} title="Tilbake til gruppeliste" />
    </Page>
  );
};

GroupInfo.propTypes = {
  authUser: PropTypes.object,
  groupDetailsState: PropTypes.object.isRequired,
  fetchGroupDetails: PropTypes.func.isRequired,
  removeMember: PropTypes.func.isRequired,
  removeGame: PropTypes.func.isRequired,
};

const mapStateToProps = ({ session, playerGroupInfo }) => ({
  authUser: session.authUser,
  groupDetailsState: playerGroupInfo.groupDetailsState,
});

const mapDispatchToProps = dispatch => ({
  fetchGroupDetails: a.getGroupDetails(dispatch),
  removeMember: a.removeMember(dispatch),
  removeGame: a.removeGame(dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupInfo);
