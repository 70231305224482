import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { CheckBox, CheckBoxOutlineBlank } from 'styled-icons/material';
import { useHistory, useParams } from 'react-router-dom';
import { RemoteData } from 'rf-comp';
import {
  Page, Text, H1, Button
} from 'rf-ui';
import { get } from 'rf-lib';
import * as a from './actions';
import { groups } from '../../../api';

const ListContainer = styled.div`
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  margin: ${get('spacingSm')};
  padding: ${get('spacingSm')};
  border: 1px solid rgba(255, 255, 255, 0.5);
`;

const ListItem = styled.div`
  ${props =>
    props.disabled
      ? css`
      color: grey;
      cursor: default;
      `
      : css`
      color: white;
      cursor: pointer;
    `};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 2px;
`;

const playerRoles = pr => {
  if (typeof pr !== 'object') {
    return ({ admin: false, accountant: false, gameMaster: false });
  }

  return Object.keys(pr || {})
    .map(key => ({
      role: key,
      authorized: pr[key]
    }));
};

const getState = (roleName, group, playerId) => {
  if (!group) return false;
  if (!group.players) return false;

  const matching = group.players.filter(p => p.id === playerId);
  if (!matching) return false;
  if (!matching[0].roles) return false;

  const roles = playerRoles(matching[0].roles);

  return roles.filter(r => r.role === roleName && r.authorized === true).length > 0;
};

const AuthDetails = ({
  authUser, group, playerId, ...props
}) => {
  const [isAdmin, setIsAdmin] = useState(getState('admin', group, playerId));
  const [isAccountant, setIsAccountant] = useState(getState('accountant', group, playerId));
  const [isGameMaster, setIsGameMaster] = useState(getState('gameMaster', group, playerId));

  if (!playerId) return (<Text>Nothing to show</Text>);

  const isCurrentUser = playerId === authUser.uid;

  const saveChanges = () => {
    const roles = {
      admin: isAdmin,
      accountant: isAccountant,
      gameMaster: isGameMaster,
    };

    groups.saveAuthorizations(group.id, playerId, roles);
  };

  return (
    <ListContainer>
      <ListItem disabled={isCurrentUser}>
        {(isAdmin && isCurrentUser) && <CheckBox size="2rem" />}
        {(isAdmin && !isCurrentUser) && <CheckBox size="2rem" onClick={() => setIsAdmin(false)} /> }
        {!isAdmin && <CheckBoxOutlineBlank size="2rem" onClick={() => setIsAdmin(true)} /> }
        Admin
      </ListItem>

      <ListItem>
        {isAccountant && <CheckBox size="2rem" onClick={() => setIsAccountant(false)} /> }
        {!isAccountant && <CheckBoxOutlineBlank size="2rem" onClick={() => setIsAccountant(true)} /> }
        <Text>Accountant</Text>
      </ListItem>

      <ListItem>
        {isGameMaster && <CheckBox size="2rem" onClick={() => setIsGameMaster(false)} /> }
        {!isGameMaster && <CheckBoxOutlineBlank size="2rem" onClick={() => setIsGameMaster(true)} /> }
        <Text>GameMaster</Text>
      </ListItem>
      <Button title="Lagre" onClick={saveChanges} />
    </ListContainer>
  );
};

AuthDetails.propTypes = {
  authUser: PropTypes.object,
  group: PropTypes.object.isRequired,
  playerId: PropTypes.string.isRequired,
};

const Authorizations = ({ authUser, ...props }) => {
  const { groupId, playerId } = useParams();

  const history = useHistory();

  useEffect(() => {
    props.fetchGroupDetails(groupId);
  }, []);

  return (
    <Page>
      <RemoteData
          type={props.groupDetailsState}
          loading={() => <H1>Henter rolleinformasjon...</H1>}
          failure={err => <span>Feil: {err.message}</span>}
          success={group => (
            <AuthDetails
              authUser={authUser}
              group={group}
              playerId={playerId}
              removeMember={props.removeMember}
              removeGame={props.removeGame} />
          )}
      />
      <Button onClick={() => history.goBack()} title="Tilbake" />
    </Page>
  );
};

Authorizations.propTypes = {
  authUser: PropTypes.object,
  groupDetailsState: PropTypes.object.isRequired,
  fetchGroupDetails: PropTypes.func.isRequired,
  removeMember: PropTypes.func.isRequired,
  removeGame: PropTypes.func.isRequired,
};

const mapStateToProps = ({ session, playerGroupInfo }) => ({
  authUser: session.authUser,
  groupDetailsState: playerGroupInfo.groupDetailsState,
});

const mapDispatchToProps = dispatch => ({
  fetchGroupDetails: a.getGroupDetails(dispatch),
  removeMember: a.removeMember(dispatch),
  removeGame: a.removeGame(dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Authorizations);
