import { toAction } from 'rf-lib';
import { messages } from '../../api';
import * as t from './constants';

export const getMessages = dispatch =>
  gameId => {
    dispatch(toAction(t.FETCH_MESSAGES));

    return messages.on(
      gameId,
      updatedMessages =>
        dispatch(toAction(t.FETCH_MESSAGES_SUCCESS, updatedMessages)),
      err =>
        dispatch(toAction(t.FETCH_MESSAGES_FAILURE, err)),
    );
  };

export const onCreateMessage = dispatch =>
  (gameId, text, authUser) => {
    dispatch(toAction(t.CREATE_MESSAGE));

    return messages.create(gameId, {
      gameId,
      text,
      player: authUser,
      playerId: authUser.uid,
      createdAt: Date.now(),
      editedAt: Date.now(),
    })
      .then(m => dispatch(toAction(t.CREATE_MESSAGE_SUCCESS, m)))
      .catch(err => dispatch(toAction(t.CREATE_MESSAGE_FAILURE, err)));
  };

export const onRemoveMessage = dispatch =>
  (gameId, uid) => {
    dispatch(toAction(t.REMOVE_MESSAGE));

    return messages.remove(gameId, uid)
      .then(() => dispatch(toAction(t.REMOVE_MESSAGE_SUCCESS)))
      .catch(err => dispatch(toAction(t.REMOVE_MESSAGE_FAILURE, err)));
  };

export const onEditMessage = dispatch =>
  (gameId, messageToEdit, editedText) => {
    dispatch(toAction(t.EDIT_MESSAGE));
    const { ...messageSnapshot } = messageToEdit;

    messages.update(gameId, {
      ...messageSnapshot,
      text: editedText,
      editedAt: Date.now(),
    })
      .then(() => dispatch(toAction(t.EDIT_MESSAGE_SUCCESS)))
      .catch(err => dispatch(toAction(t.EDIT_MESSAGE_FAILURE, err)));
  };

