export const SET_AUTH_USER = 'SET_AUTH_USER';
export const SET_AUTH_FAILURE = 'SET_AUTH_FAILURE';

export const SIGN_OUT = 'SIGN_OUT';
export const SIGN_OUT_FAILURE = 'SIGN_OUT_FAILURE';

export const UPDATE_PLAYER = 'player/UPDATE_PLAYER';
export const UPDATE_PLAYER_SUCCESS = 'player/UPDATE_PLAYER_SUCCESS';
export const UPDATE_PLAYER_FAILURE = 'player/UPDATE_PLAYER_FAILURE';

export const FETCH_PLAYERS = 'players/FETCH_PLAYERS';
export const FETCH_PLAYERS_FAILURE = 'players/FETCH_PLAYERS_FAILURE';
export const FETCH_PLAYERS_SUCCESS = 'players/FETCH_PLAYERS_SUCCESS';

export const SET_REDIRECT_PATH = 'session/SET_REDIRECT_PATH';

export const AUTHORIZATIONS_LISTENER_SUCCESS = 'AUTHORIZATIONS_LISTENER_SUCCESS';
export const AUTHORIZATIONS_LISTENER_FAILURE = 'AUTHORIZATIONS_LISTENER_FAILURE';
