import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const TransactionContainer = styled.div`
display: contents;
box-sizing: border-box;
padding: 10px;
float: right;
flex-direction: column;
align-items: center;
`;

const Line = styled.div`
border: 1px dashed lightgrey; 
width: 100%;
margin: auto; 
margin-top: 1%; 
margin-bottom: 1%;
`;

const Box = styled.div`
display: flex;
justify-content: space-between;
`;

const LeftOrientedBox = styled.div`
width: 33%;
`;

const RightOrientedBox = styled.div`
width: 33%;
text-align: end;
`;

export const getPlayerBalance = (sum, value, type) => (type === 'purchase')? Number(sum) - Number(value) : Number(sum) + Number(value);

const TransactionList = ({ ...props }) => {
  let totalamount = 0;
  return (
    <div>
      <Box>Siste 20 innbetalinger og kjøp</Box>
      {(props.transactions)? (
        <TransactionContainer>
          <Box>
            <LeftOrientedBox>Dato</LeftOrientedBox>
            <LeftOrientedBox>Type</LeftOrientedBox>
            <RightOrientedBox>Beløp</RightOrientedBox>
          </Box>
          <Line />
          {Object.values(props.transactions)
            .sort((a, b) => (a.date > b.date ? -1: (b.date > a.date) ? 1 : 0))
            .slice(0, props.totalTransactionsShown)
            .map((transaction, index) => {
              totalamount= getPlayerBalance(totalamount,
                transaction.amount,
                transaction.type);
              return (
                <div key={index}>
                  <Box>
                    <LeftOrientedBox>{new Date(transaction.date)
                      .toLocaleString()}
                    </LeftOrientedBox>
                    <LeftOrientedBox>{(transaction.type === 'purchase')?
                      'Kjøp':
                      'Innbetaling'}
                    </LeftOrientedBox>
                    <RightOrientedBox>{(transaction.type === 'purchase')?
                      (-1) * transaction.amount:
                      transaction.amount}
                    </RightOrientedBox>
                  </Box>
                </div>
              );
            })}
          <Line />
        </TransactionContainer>
      ) :
        <div>Ingen registrerte transaksjoner</div>}
    </div>
  );
};

TransactionList.propTypes = {
  transactions: PropTypes.object,
  totalTransactionsShown: PropTypes.number.isRequired
};

export default TransactionList;
