import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { get } from 'rf-lib';
import { H1 } from './Headers';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: dimgray;
  color: #fff;
  background: linear-gradient(-45deg, #EE7752, #E73C7E, #23A6D5, #23D5AB);
  background-size: 400% 400%;
  -webkit-animation: Gradient 15s ease infinite;
  -moz-animation: Gradient 15s ease infinite;
  animation: Gradient 15s ease infinite;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
`;

const FormContainer = styled.div`
  box-shadow: 0 4px 8px 0 rgb(0, 0, 0), 0 6px 20px 0 rgba(0, 0, 0, 0.48);
  padding: 0 ${get('spacingXxl')} ${get('spacingLg')} ${get('spacingXxl')};
  background: ${get('colorBackgroundPrimary')};
  display: flex;
  flex-direction: column;

  button {
    margin-top: 1rem;
  }
`;

const FormPageWrapper = ({ title, children }) => (
  <Container>
    <Content>
      <FormContainer>
        <H1>{title}</H1>
        {children}
      </FormContainer>
    </Content>
  </Container>
);

FormPageWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired
};

export default FormPageWrapper;
