import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Text, Button, H3, Page, Bar
} from 'rf-ui';
import { People } from 'styled-icons/material';
import { RemoteData } from 'rf-comp';
import { useRemoteService, get } from 'rf-lib';
import { groups as groupsAPI } from 'rf-api';
import * as paths from 'rf-lib/routePaths';
import * as a from '../playerGroupInfo/actions';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 500px;
  margin: ${get('spacingSm')} 0 ${get('spacingMd')} 0;
`;

const GroupContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin-left: ${get('spacingMd')};
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin-top: ${get('spacingMd')};
`;

const InviteToGroupInfo = ({ playerIsMember, group }) => {
  const history = useHistory();
  const [remoteJoinGroup, addMember] = useRemoteService(groupsAPI.addMember);

  return (
    <div>
      <Text> Du er invitert til spillgruppen:</Text>
      <Bar>
        <GroupContainer>
          {group.imageUrl ? (
            <img src={group.imageUrl} alt="img" width="100" />
          ) : <People width="100" />}
          <TextContainer>
            <H3>{group.groupName}</H3>
            <Text> {group.groupDescription} </Text>
          </TextContainer>
        </GroupContainer>
      </Bar>
      <ButtonsContainer>
        {playerIsMember ? (
          <>
            <Text> Du er allerede medlem i gruppen! </Text>
            <br />
            <Button
            onClick={() => history.push(`/groups/info/${group.id}`)}
            title="Gå til spillergruppe" />
          </>
        ) : (
          <RemoteData type={remoteJoinGroup}
              notAsked={() => <Button title="Bli medlem" onClick={() => addMember(group.id)} />}
              loading={() => <span>Legger til...</span>}
              failure={err => <span>Feil: {err.message}</span>}
              success={() => (
                <div>
                  <Text>Du er nå med i gruppen!</Text>
                  <br />
                  <Button
                  onClick={() => history.push(`/groups/info/${group.id}`)}
                  title="Gå til spillergruppe" />
                </div>
              )}
            />
        )}
      </ButtonsContainer>
    </div>
  );
};

InviteToGroupInfo.propTypes = {
  group: PropTypes.object.isRequired,
  playerIsMember: PropTypes.bool.isRequired,
};

const mapCombineStates = (groupDetailsState, isPlayerMember) =>
  groupDetailsState.flatMap(group =>
    isPlayerMember.map(isMember => ({
      group,
      isMember
    })));

const InviteToGroup = ({ authUser, ...props }) => {
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    props.fetchGroupDetailed(id);
    props.isPlayerMember(authUser.uid, id);
  }, []);

  return (
    <PageContainer>
      <Page>
        <RemoteData
          type={mapCombineStates(props.groupDetailsState, props.isPlayerMemberState)}
          notAsked={() => <span>Prosess ikke startet</span>}
          loading={() => <span>Henter gruppedetaljer...</span>}
          failure={err => <span>Feil: {err.message}</span>}
          success={({ group, isMember }) => (
            <InviteToGroupInfo group={group} playerIsMember={isMember} />
          )}
        />
        <ButtonsContainer>
          <Button
              onClick={() => history.push(paths.DASHBOARD)}
              title="Tilbake til dashboard" />
        </ButtonsContainer>
      </Page>
    </PageContainer>
  );
};

InviteToGroup.propTypes = {
  authUser: PropTypes.object.isRequired,
  fetchGroupDetailed: PropTypes.func.isRequired,
  groupDetailsState: PropTypes.object.isRequired,
  isPlayerMemberState: PropTypes.object.isRequired,
  isPlayerMember: PropTypes.func.isRequired,
};

const mapStateToProps = ({ session, playerGroupInfo }) => ({
  authUser: session.authUser,
  ...playerGroupInfo
});

const mapDispatchToProps = dispatch => ({
  fetchGroupDetailed: a.getGroupDetails(dispatch),
  isPlayerMember: a.isPlayerMember(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InviteToGroup);
