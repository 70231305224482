import React from 'react';
import styled from 'styled-components';
import { get } from 'rf-lib';
import PropTypes from 'prop-types';
import { SmallButton } from 'rf-ui';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

const MessageRow = styled.div`
  display: flex;  
  flex-direction: ${props => props.flexDirection};
  justify-content: ${props => props.justifyContent};
  width: 80%;
  margin-left: ${props => props.hasLeftMargin ? '15%' : '0%'};
  margin-right: ${props => props.hasLeftMargin ? '0%' : '15%'};
`;

const ProfileContainer = styled.div`
flex: 0 1 10%;
background: ${get('colorBackgroundPrimary')};
padding: ${get('spacingSm')};
margin: ${get('spacingSm')};
`;

const MessageContainer = styled.div`  
  flex: 1 1 auto;
  background: ${props => props.backgroundColor};
  padding: ${get('spacingSm')};
  margin: ${get('spacingSm')};
  width: 100%;
  display: block;
  border-radius: ${get('spacingSm')};
  border-color: ${get('colorPrimary')};
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
`;

const TextArea = styled.textarea`
  resize: none;
  border-radius: 3px;
  background: ${props => props.backgroundColor};
  cursor: pointer;
  width: 100%;
  background-color: ${props => props.backgroundColor};
  color: #ffffff;
  border: none;
  padding: 0.6rem;
  height: auto;
`;

const backgroundColor = isMine =>
  isMine
    ? get('colorBackgroundSecondary')
    : get('colorBackgroundPrimary');

const justifyContent = isMine => isMine? 'flex-end': 'flex-start';

const flexDirection = isMine => isMine? 'row-reverse': 'row';

const GetDate = timeStamp => {
  const date = new Date(timeStamp);
  return (date.toLocaleDateString('nb-NO', { dateStyle: 'short' }));
};

const validationSchema =
  Yup.object({
    text: Yup.string()
      .max(50, 'Redigeringstekst er for lang, tekst skal være mellom 1 - 50 bokstaver')
      .min(1, 'Redigeringstekst er for kort, tekst skal være mellom 1 - 50 bokstaver')
      .required('Redigeringstekst kan ikke være tom')
  });

const Profile = ({ player, timeStamp, ...props }) => (
  <ProfileContainer>
    {player.displayName} {GetDate(timeStamp)}
  </ProfileContainer>
);

Profile.propTypes = {
  player: PropTypes.object.isRequired,
  timeStamp: PropTypes.number.isRequired,
};

const FormValues = ({
  isMine, authUser, formikValues, message
}) => (
  <Form onSubmit={formikValues.handleSubmit}>
    <MessageContainer backgroundColor={backgroundColor(isMine)}>
      <TextArea
    name="text"
    disabled={!formikValues.values.editMode}
    value={formikValues.values.text}
    backgroundColor={backgroundColor(isMine)}
    onChange={e => {
      formikValues.handleChange(e);
    }}
    />
    </MessageContainer>
    {(authUser.uid === message.playerId) ?
      (
        <div>
          <SmallButton title={formikValues.values.editMode ? 'Save' : 'Edit'}
            id="edit-save-button"
            type="button"
            onClick={e => {
              if (formikValues.values.editMode) {
                formikValues.setFieldValue('shouldSave', true);
                if (formikValues.values.text.length < 1 || formikValues.values.text.length > 51) {
                  formikValues.setFieldValue('text', formikValues.values.temptext);
                }
              }
              formikValues.setFieldValue('editMode', !formikValues.values.editMode);
              formikValues.handleSubmit(e);
            }} />
          <SmallButton title={formikValues.values.editMode ? 'Cancel' : 'Delete'}
            id="delete-cancel-button"
            type="button"
            onClick={e => {
              if (!formikValues.values.editMode) {
                formikValues.setFieldValue('shouldDelete', true);
              } else {
                formikValues.setFieldValue('text', formikValues.values.temptext);
              }
              formikValues.setFieldValue('editMode', !formikValues.values.editMode);
              formikValues.handleSubmit(e);
            }} />
        </div>
      ) : (
        <div />
      )}
  </Form>
);

FormValues.propTypes = {
  authUser: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
  isMine: PropTypes.bool,
  formikValues: PropTypes.object.isRequired,
};

const MessageForm = ({
  isMine, authUser, message, onRemoveMessage, onEditMessage, gameId
}) => (
  <Formik
initialValues={{
  text: message.text,
  temptext: message.text,
  editMode: false,
  shouldSave: false,
  shouldDelete: false,
}}
      validationSchema={validationSchema}
      onSubmit={(values, { setFieldValue }) => {
        if (values.shouldDelete) {
          onRemoveMessage(gameId, message.uid);
          setFieldValue('shouldDelete', false);
        }
        if (values.shouldSave) {
          onEditMessage(gameId, message, values.text);
          setFieldValue('temptext', values.text);
          setFieldValue('shouldSave', false);
        }
      }}
    >{formikValues =>
      (
        <FormValues
          formikValues={formikValues}
          authUser={authUser}
          isMine={isMine}
          message={message} />
      )}
  </Formik>
);

MessageForm.propTypes = {
  authUser: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
  onRemoveMessage: PropTypes.func,
  onEditMessage: PropTypes.func,
  gameId: PropTypes.string,
  isMine: PropTypes.bool,
};

const MessageItem = ({
  authUser, message, onRemoveMessage, onEditMessage, gameId, ...props
}) => {
  const isMine = authUser.uid === message.playerId;
  return (
    <MessageRow
      flexDirection={flexDirection(isMine)}
      justifyContent={justifyContent(isMine)}
      hasLeftMargin={isMine}
    >
      <Profile player={message.player} timeStamp={message.editedAt} />
      <MessageForm
       isMine={isMine}
       authUser={authUser}
       message={message}
       onRemoveMessage={onRemoveMessage}
       onEditMessage={onEditMessage}
       gameId={gameId}
      />
    </MessageRow>
  );
};

MessageItem.propTypes = {
  authUser: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
  onRemoveMessage: PropTypes.func,
  onEditMessage: PropTypes.func,
  gameId: PropTypes.string,
};

export default MessageItem;
