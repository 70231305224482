import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { RemoteData } from 'rf-comp';
import { H3, SmallButton, FormField } from 'rf-ui';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { messages as messagesApi } from '../../api';
import * as a from './actions';
import * as store from '../../store/actions';
import MessageList from './MessageList';

const mapCombineStates = (firstState, secondState) =>
  firstState.flatMap(remoteData1 =>
    secondState.map(remoteData2 => ({
      remoteData1,
      remoteData2
    })));

const validationSchema =
  Yup.object({
    text: Yup.string()
      .max(50, 'Tekst for lang, tekst skal være mellom 1 - 50 bokstaver')
      .min(1, 'Tekst for kort, tekst skal være mellom 1 - 50 bokstaver')
      .required('Tekst kan ikke være tom')
  });

const NewMessageForm = ({ gameId, ...props }) => (
  <Formik initialValues={{ text: '' }}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={(values, { setFieldValue }) => {
              props.onCreateMessage(gameId, values.text, props.authUser);
              setFieldValue('text', '');
            }}
          >
    <Form>
      <FormField autoFocus name="text" title="" />
      <SmallButton title="Send" type="submit" />
    </Form>
  </Formik>
);

NewMessageForm.propTypes = {
  authUser: PropTypes.object,
  gameId: PropTypes.string,
  onCreateMessage: PropTypes.func,
};

const ShowMessages = ({
  gameId, messages, ...props
}) => (
  <div>
    {messages && props.authUser && (
      <MessageList
        authUser={props.authUser}
        messages={messages}
        onEditMessage={props.onEditMessage}
        onRemoveMessage={props.onRemoveMessage}
        gameId={gameId}
      />
    )}
    {!messages && <div>There are no messages ...</div>}
    <NewMessageForm gameId={gameId} {...props} />
    <RemoteData type={mapCombineStates(props.createmessageState, props.editMessageState)}
        notAsked={() => <div />}
        loading={() => <span>lagrer melding...</span>}
        failure={err => <span>feil under lagring: {err} </span>}
        success={() => <span />}
      />
  </div>
);

ShowMessages.propTypes = {
  messages: PropTypes.array,
  authUser: PropTypes.object,
  onRemoveMessage: PropTypes.func.isRequired,
  gameId: PropTypes.string,
  onCreateMessage: PropTypes.func,
  createmessageState: PropTypes.object.isRequired,
  editMessageState: PropTypes.object.isRequired,
  onEditMessage: PropTypes.func.isRequired,
};

const Messages = ({ gameId, ...props }) => {
  const onUnmount = () => {
    messagesApi.off();
  };
  useEffect(
    () => {
      props.getPlayers();
      props.getMessages(gameId);
      return onUnmount;
    },
    []
  );
  return (
    <div>
      <H3>Chat</H3>
      <RemoteData type={mapCombineStates(props.messageState, props.playersRemoteData)}
        notAsked={() => <span>Prosess ikke startet</span>}
        loading={() => <span>Henter...</span>}
        failure={err => <span>Feil under henting: {err}</span>}
        success={messages => <ShowMessages gameId={gameId} messages={messages} {...props} />}
      />
    </div>
  );
};

Messages.propTypes = {
  getMessages: PropTypes.func.isRequired,
  getPlayers: PropTypes.func.isRequired,
  authUser: PropTypes.object,
  gameId: PropTypes.string.isRequired,
  messageState: PropTypes.object.isRequired,
  playersRemoteData: PropTypes.object.isRequired,
  onCreateMessage: PropTypes.func.isRequired,
  createmessageState: PropTypes.object.isRequired,
  onRemoveMessage: PropTypes.func.isRequired,
  onEditMessage: PropTypes.func.isRequired,
  editMessageState: PropTypes.object.isRequired,
};

const mapStateToProps = ({ session, messages }) => ({
  authUser: session.authUser,
  ...messages,
  playersRemoteData: session.playersRemoteData,
});

const mapDispatchToProps = dispatch => ({
  getPlayers: store.fetchAllPlayers(dispatch),
  getMessages: a.getMessages(dispatch),
  onCreateMessage: a.onCreateMessage(dispatch),
  onRemoveMessage: a.onRemoveMessage(dispatch),
  onEditMessage: a.onEditMessage(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Messages);
