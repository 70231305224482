import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { variant } from 'rf-lib';

const background = variant({
  primary: 'linear-gradient(#11a598, #3f79c2)',
  secondary: 'linear-gradient(#a641f5, #891292)'
});

const LinkButton = styled(Link)`
  color: white;
  text-decoration: none;
  border: none;
  padding: 1rem 1rem;
  margin: 2px;
  border-radius: 6px;
  font-weight: 400;
  font-size: 1em;
  cursor: pointer;
  text-align: center;
  background: ${background};
`;

LinkButton.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary'])
};

LinkButton.defaultProps = {
  variant: 'primary',
};

export default LinkButton;
