import firebase from '../firebase/firebase';
import hashmapToArray from './mappers';

const messageRef = (gameId, messageId) => firebase.db.ref(`chats/${gameId}/${messageId}`);

const messagesRef = gameId => firebase.db.ref(`chats/${gameId}`);

export const create = (gameId, message) =>
  messagesRef(gameId).push(message);

export const update = (gameId, message) => {
  const { uid: key, ...storable } = message;
  return messageRef(gameId, key).set(storable);
};

export const remove = (gameId, id) =>
  messageRef(gameId, id).remove();

export const on = (gameId, successCallback, errorCallback) =>
  messagesRef(gameId).on('value', hashmapToArray(successCallback), errorCallback);

export const off = gameId =>
  messagesRef(gameId).off();
