import daggy from 'daggy';

const RemoteData = daggy.taggedSum('RemoteData', {
  NotAsked: [],
  Loading: [],
  Failure: ['error'],
  Success: ['data']
});

RemoteData.prototype.map = function map(f) {
  return this.cata({
    Success: x => RemoteData.Success(f(x)),
    NotAsked: () => this,
    Loading: () => this,
    Failure: _ => this,
  });
};

RemoteData.prototype.isNotAsked = function isNotAsked() {
  return this.cata({
    NotAsked: () => true,
    Loading: () => false,
    Failure: _ => false,
    Success: _ => false
  });
};

RemoteData.prototype.isLoading = function isLoading() {
  return this.cata({
    NotAsked: () => false,
    Loading: () => true,
    Failure: _ => false,
    Success: _ => false
  });
};

RemoteData.prototype.isFailure = function isFailure() {
  return this.cata({
    NotAsked: () => false,
    Loading: () => false,
    Failure: _ => true,
    Success: _ => false
  });
};

RemoteData.prototype.isSuccess = function isSuccess() {
  return this.cata({
    NotAsked: () => false,
    Loading: () => false,
    Failure: _ => false,
    Success: _ => true
  });
};

RemoteData.prototype.flatMap = function flatMap(fnThatReturnsRemoteData) {
  return this.cata({
    NotAsked: () => this,
    Loading: () => this,
    Failure: _ => this,
    Success: data => fnThatReturnsRemoteData(data)
  });
};

export default RemoteData;
