import { toAction } from 'rf-lib';
import { groups, games } from '../../../api';
import * as t from './constants';

export const getGroupDetails = dispatch =>
  groupId => {
    dispatch(toAction(t.FETCH_GROUP_DETAILS));

    return groups.fetchGroupDetails(groupId)
      .then(groupsData => {
        if (!groupsData.groupName) {
          dispatch(toAction(t.FETCH_GROUP_DETAILS_FAILURE, { message: `Gruppen med id ${groupId} finnes ikke!` }));
        } else {
          dispatch(toAction(t.FETCH_GROUP_DETAILS_SUCCESS, groupsData));
        }
      })
      .catch(err => dispatch(toAction(t.FETCH_GROUP_DETAILS_FAILURE, err)));
  };

export const isPlayerMember = dispatch =>
  (playerId, groupId) => {
    dispatch(toAction(t.IS_PLAYER_MEMBER));

    return groups.fetchGroupPlayers(groupId)
      .then(players => !!players.find(player => player.id === playerId))
      .then(membershipStatus => dispatch(toAction(t.IS_PLAYER_MEMBER_SUCCESS, membershipStatus)))
      .catch(err => dispatch(toAction(t.IS_PLAYER_MEMBER_FAILURE, err)));
  };

export const removeMember = dispatch =>
  (groupId, playerId) => {
    dispatch(toAction(t.DELETE_MEMBER));

    return groups.removeMember(groupId, playerId)
      .then(() => dispatch(toAction(t.DELETE_MEMBER_SUCCESS)))
      .catch(err => dispatch(toAction(t.DELETE_MEMBER_FAILURE, err)));
  };

export const removeGame = dispatch =>
  (groupId, playerId) => {
    dispatch(toAction(t.DELETE_GAME));

    return games.deleteGame(groupId, playerId)
      .then(() => dispatch(toAction(t.DELETE_GAME_SUCCESS)))
      .catch(err => dispatch(toAction(t.DELETE_GAME_FAILURE, err)));
  };

