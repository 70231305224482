import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { get } from 'rf-lib';
import { Link, Redirect } from 'react-router-dom';
import { RemoteData } from 'rf-comp';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  FormPage, FormField, Button
} from 'rf-ui';
import * as paths from 'rf-lib/routePaths';
import createUser from './actions';

const LinksContainer = styled.div`
  margin-top: ${get('spacingLg')};
  display: flex;
  flex-direction: column;
  align-items: center;

  a:first-child {
    margin-bottom: ${get('spacingMd')};
  }
`;

const Schema =
  Yup.object({
    email: Yup.string()
      .email('Ugyldig e-postadresse')
      .max(255, 'E-postadresse kan ha maksimalt 255 tegn')
      .required('E-postadresse må fylles ut'),
    password1: Yup.string()
      .min(6, 'Passordet må ha minst 6 tegn')
      .max(255, 'Passordet kan ha maksimalt 255 tegn')
      .matches('^.*[0-9].*$', 'Passordet må ha minst ett siffer')
      .required('Passord må fylles ut'),
    password2: Yup.string()
      .min(6, 'Passordet må ha minst 6 tegn')
      .max(255, 'Passordet kan ha maksimalt 255 tegn')
      .matches('^.*[0-9].*$', 'Passordet må ha minst ett siffer')
      .required('Passord må fylles ut')
      .oneOf([Yup.ref('password1'), null], 'Passordene må være like'),
    displayName: Yup.string()
      .min(3, 'Brukernavnet må være på minst 3 tegn')
      .max(15, 'Brukernavnet kan ha maksimalt 15 tegn')
      .required('Brukernavn må fylles ut')
  });

const RegisterPage = ({ createNewUser, registerState, ...props }) => (
  <FormPage title="Lag ny konto">
    <Formik
        initialValues={{
          email: '', password1: '', password2: '', displayName: ''
        }}
        validationSchema={Schema}
        onSubmit={values => {
          createNewUser(values.email, values.password1, values.displayName);
        }}
        validateOnChange={false}
        validateOnBlur={false}
        >
      <Form>
        <FormField
            name="displayName"
            title="Brukernavn"
          />
        <FormField
            name="email"
            title="E-post"
          />
        <FormField
            name="password1"
            title="Passord"
            type="password"
          />
        <FormField
            name="password2"
            title="Gjenta passord"
            type="password"
          />
        <Button title="Opprett" type="submit" />
        <LinksContainer>
          <Link to="/login">Er du allerede en bruker, logg inn her</Link>
        </LinksContainer>
        <RemoteData
            type={registerState}
            loading={() => <span>Sender brukerdata...</span>}
            failure={err => <span>Feil: {err.message}</span>}
            success={() => <span><Redirect push to={paths.HOME} /> </span>}
        />
      </Form>
    </Formik>
  </FormPage>
);

RegisterPage.propTypes = {
  createNewUser: PropTypes.func.isRequired,
  registerState: PropTypes.object.isRequired,
};

const mapStateToProps = ({ register }) => ({
  registerState: register.registerState,
});

const mapDispatchToProps = dispatch => ({
  createNewUser: createUser(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterPage);
