import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { head } from 'ramda';
import { get, fileToB64 } from 'rf-lib';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  img {
    max-width: 18.75rem;
    max-height: 12.5rem;
    margin: ${get('spacingLg')} 0 ${get('spacingXxl')} 0;
  }
`;

const FileField = styled.input`
  visibility: hidden;
  width: 0;
  height: 0;
`;

const UploadButton = styled.label`
  cursor: pointer;
  padding: 1rem;
  border-radius: 0.5rem;
  background: linear-gradient(#11a598, #3f79c2)
`;

const ImageUpload = ({ title, url, onImage }) => {
  const [b64, setB64] = useState('');

  const onChange = evt => {
    const file = head(evt.target.files);

    fileToB64(file)
      .then(b64Image => {
        setB64(b64Image);
        onImage(file);
      });
  };

  return (
    <Container>
      <UploadButton htmlFor="image-field">{title}</UploadButton>
      <FileField type="file" id="image-field" accept="image/png, image/jpeg" onChange={onChange} />
      {b64 || url ? (
        <img src={b64 || url} alt="img" />
      ) : null}
    </Container>
  );
};

ImageUpload.propTypes = {
  title: PropTypes.string.isRequired,
  onImage: PropTypes.func.isRequired,
  url: PropTypes.string,
};

export default ImageUpload;
