import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Page, Bar, LinkButton, Button, H1, H3, Text
} from 'rf-ui';
import { People } from 'styled-icons/material';
import { RemoteData } from 'rf-comp';
import { get, isAuthorized } from 'rf-lib';
import { Link, useHistory } from 'react-router-dom';
import * as a from './actions';

const GroupContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin-left: ${get('spacingMd')};
`;

const Group = ({ group, authUser }) => {
  const userIsAdmin = isAuthorized('admin', group.id, authUser);
  const userIsAccountant = isAuthorized('accountant', group.id, authUser);

  const history = useHistory();

  const showEditGroup = event => {
    history.push(`/groups/edit/${group.id}`);
    event.preventDefault();
  };

  const editPayments = event => {
    history.push(`/balance/registerpayment/${group.id}`);
    event.preventDefault();
  };

  const addGame = event => {
    history.push(`/games/new/${group.id}`);
    event.preventDefault();
  };

  return (
    <Link to={`/groups/info/${group.id}`} width="100%">
      <Bar key={group.key}>
        <GroupContainer>
          {group.imageUrl ? (
            <img src={group.imageUrl} alt="img" width="100" />
          ) : <People width="100" />}
          <TextContainer>
            <H3> {group.groupName} </H3>
            <Text> {group.groupDescription} </Text>
          </TextContainer>
        </GroupContainer>
        {userIsAdmin && <Button title="Rediger" onClick={showEditGroup} />}
        {userIsAccountant && <Button title="Innbetalinger" onClick={editPayments} />}
        {userIsAdmin && <Button title="Nytt spill" onClick={addGame} />}
      </Bar>
    </Link>
  );
};

Group.propTypes = {
  group: PropTypes.object.isRequired,
  authUser: PropTypes.object.isRequired,
};

const ViewPlayerGroups = ({ authUser, ...props }) => {
  useEffect(
    () => {
      props.resetState();
      props.fetchPlayerGroups(authUser.uid);
    }, []
  );

  return (
    <Page>
      <H1>Spillergrupper</H1>
      <LinkButton variant="secondary" to="/groups/new">Lag spillergruppe</LinkButton>
      <br />
      <br />
      <RemoteData
        type={props.playerGroupsState}
        loading={() => <span>Henter grupper...</span>}
        failure={err => <span>Feil: {err.message}</span>}
        success={groups => groups.map(group =>
          <Group key={group.id} group={group} authUser={authUser} />)}
      />
    </Page>
  );
};

ViewPlayerGroups.propTypes = {
  authUser: PropTypes.object,
  playerGroupsState: PropTypes.object.isRequired,
  fetchPlayerGroups: PropTypes.func.isRequired,
  resetState: PropTypes.func.isRequired,
};

const mapStateToProps = ({ session, playerGroups }) => ({
  authUser: session.authUser,
  playerGroupsState: playerGroups.playerGroupsState,
});

const mapDispatchToProps = dispatch => ({
  fetchPlayerGroups: a.getPlayerGroups(dispatch),
  resetState: a.resetState(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewPlayerGroups);
