import { toAction } from 'rf-lib';
import { groups } from '../../../api';
import * as t from './constants';

export const getPlayerGroups = dispatch =>
  playerId => {
    dispatch(toAction(t.FETCH_PLAYER_GROUPS));

    return groups.fetchPlayerGroups(playerId)
      .then(groupsData => dispatch(toAction(t.FETCH_PLAYER_GROUPS_SUCCESS, groupsData)))
      .catch(err => dispatch(toAction(t.FETCH_PLAYER_GROUPS_FAILURE, err)));
  };

export const resetState = dispatch => () => { dispatch(toAction(t.RESET_STATE)); };
