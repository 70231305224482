import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { RemoteData } from 'rf-comp';
import { Button } from 'rf-ui';
import * as a from './actions';

const FillTestData = ({ gameId, fillTestData, ...props }) => {
  useEffect(
    () => {
      props.resetFillTestDataRemoteData();
    }, []
  );

  return (
    <RemoteData type={props.fillTestDataRemoteData}
          notAsked={() => <Button title="Fyll inn testdata" onClick={() => fillTestData(gameId)} />}
          loading={() => <span>Skriver testdata...</span>}
          failure={err => <span>Feil under skriving: {err.message}</span>}
          success={() => <span>Testdata er fylt ut!</span>}
          />
  );
};

FillTestData.propTypes = {
  gameId: PropTypes.string.isRequired,
  fillTestData: PropTypes.func.isRequired,
  fillTestDataRemoteData: PropTypes.object.isRequired,
  resetFillTestDataRemoteData: PropTypes.func.isRequired,
};

const mapStateToProps = ({ games }) => ({
  fillTestDataRemoteData: games.fillTestDataRemoteData,
});

const mapDispatchToProps = dispatch => ({
  fillTestData: a.fillTestData(dispatch),
  resetFillTestDataRemoteData: a.resetFillTestDataRemoteData(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FillTestData);
