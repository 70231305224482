export const FETCH_MESSAGES = 'messages/FETCH_MESSAGES';
export const FETCH_MESSAGES_FAILURE = 'messages/FETCH_MESSAGES_FAILURE';
export const FETCH_MESSAGES_SUCCESS = 'messages/FETCH_MESSAGES_SUCCESS';

export const REMOVE_MESSAGE = 'messages/REMOVE_MESSAGE';
export const REMOVE_MESSAGE_FAILURE = 'messages/REMOVE_MESSAGE_FAILURE';
export const REMOVE_MESSAGE_SUCCESS = 'messages/REMOVE_MESSAGE_SUCCESS';

export const CREATE_MESSAGE = 'messages/CREATE_MESSAGE';
export const CREATE_MESSAGE_FAILURE = 'messages/CREATE_MESSAGE_FAILURE';
export const CREATE_MESSAGE_SUCCESS = 'messages/CREATE_MESSAGE_SUCCESS';

export const EDIT_MESSAGE = 'messages/EDIT_MESSAGE';
export const EDIT_MESSAGE_FAILURE = 'messages/EDIT_MESSAGE_FAILURE';
export const EDIT_MESSAGE_SUCCESS = 'messages/EDIT_MESSAGE_SUCCESS';
