import React from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isEmpty } from 'ramda';
import { useField, useFormikContext } from 'formik';
import { get } from 'rf-lib';

const Error = styled.label`
  color: red;
  height: auto;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${get('spacingSm')} 0 ${get('spacingMd')} 0;

  input {
    border-radius: 3px;
    padding: 0.6rem;
    height: auto;
  }
`;

const DateField = ({
  title, type = 'datetime-local', ...props
}) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);
  return (
    <Container>
      <label htmlFor={props.id}>{title}</label>
      <DatePicker
        name="startTime"
        todayButton="Today"
        selected={(field.value && new Date(field.value)) || null}
        {...field}
        {...props}
        onChange={date => {
          setFieldValue(field.name, date);
        }}
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={15}
        dateFormat="dd/MM/yyyy HH:mm"
        type={type}
      />
      {meta.touched && !isEmpty(meta.error) ? (
        <Error className="error">{meta.error}</Error>
      ) : null}
    </Container>
  );
};

DateField.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  id: PropTypes.string,
  type: PropTypes.string
};

export default DateField;
