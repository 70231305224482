import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { get } from 'rf-lib';
import { RemoteData } from 'rf-comp';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Link, Redirect, useHistory } from 'react-router-dom';
import {
  FormField, FormPage, Button
} from 'rf-ui';
import * as paths from 'rf-lib/routePaths';
import * as a from './actions';

const LinksContainer = styled.div`
  margin-top: ${get('spacingLg')};
  display: flex;
  flex-direction: column;
  align-items: center;

  a:first-child {
    margin-bottom: ${get('spacingMd')};
  }
`;

const validationSchema =
  Yup.object({
    email: Yup.string()
      .email('Ugyldig e-postadresse')
      .required('E-post må være fylt ut'),
    password: Yup.string()
      .min(6, 'Passordet må ha minst 6 tegn')
      .required('Passord må være fylt ut')
  });

const LoginForm = ({ signInWithEmail, message }) => (
  <Formik initialValues={{ email: '', password: '' }}
          validationSchema={validationSchema}
          onSubmit={values => { signInWithEmail(values.email, values.password); }}
          validateOnChange={false}
          validateOnBlur={false}
        >
    <Form>
      <FormField autoFocus name="email" title="E-post" />
      <FormField name="password" title="Passord" type="password" />
      <Button title="Logg inn" type="submit" />
      <span>{message}</span>
    </Form>
  </Formik>
);

LoginForm.propTypes = {
  signInWithEmail: PropTypes.func.isRequired,
  message: PropTypes.string
};

const RedirectWhenAuthenticated = ({ isAuthenticated, redirectPath }) =>
  isAuthenticated
    ? <Redirect push to={redirectPath || paths.DASHBOARD} />
    : <span>Logger inn...</span>;

RedirectWhenAuthenticated.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  redirectPath: PropTypes.string
};

const LoginPage = ({
  signInWithEmail, authUser, redirectPath, clearRedirectPath, resetState, ...props
}) => {
  const history = useHistory();

  const isAuthenticated = !!authUser;

  const { url } = props.location.state || { url: redirectPath };

  useEffect(() => {
    resetState();
    return () => clearRedirectPath();
  }, []);

  return (
    <FormPage title="Logg inn">
      <RemoteData
              type={props.loginState}
              notAsked={() => <LoginForm signInWithEmail={signInWithEmail} />}
              loading={() => <span>Logger inn...</span>}
              failure={err => <LoginForm signInWithEmail={signInWithEmail} message={`Feil: ${err.message}`} />}
              success={() => (
                <RedirectWhenAuthenticated
                  isAuthenticated={isAuthenticated}
                  redirectPath={url} />
              )}
            />
      <Button title="Avbryt" type="button" onClick={() => history.push('/')} />
      <LinksContainer>
        <Link to="/reset-password">Glemt passord?</Link>
        <Link to="/register">Registrer en ny bruker</Link>
      </LinksContainer>
    </FormPage>
  );
};

LoginPage.propTypes = {
  signInWithEmail: PropTypes.func.isRequired,
  loginState: PropTypes.object.isRequired,
  authUser: PropTypes.object,
  redirectPath: PropTypes.string,
  clearRedirectPath: PropTypes.func.isRequired,
  resetState: PropTypes.func.isRequired,
  location: PropTypes.object,
};

const mapStateToProps = ({ login, session }) => ({
  loginState: login.loginState,
  authUser: session.authUser,
  redirectPath: session.redirectPath,
});

const mapDispatchToProps = dispatch => ({
  signInWithEmail: a.signInWithEmail(dispatch),
  clearRedirectPath: a.clearRedirectPath(dispatch),
  resetState: a.resetState(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
