import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useField } from 'formik';
import { get } from 'rf-lib';
import { isEmpty } from 'ramda';
import Dropdown from './Dropdown';

const Error = styled.label`
  color: red;
  height: auto;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${get('spacingSm')} 0 ${get('spacingMd')} 0;
`;

const DropdownField = ({
  items, title, onChange, ...props
}) => {
  const [field, meta] = useField(props);

  return (
    <>
      <Container>
        <label htmlFor={props.id}>{title}</label>
        <Dropdown
        items={items}
        title="Velg spillergruppe"
        {...field}
      />
        {meta.touched && !isEmpty(meta.error) ? (
          <Error className="error">{meta.error}</Error>
        ) : null}
      </Container>
    </>
  );
};

DropdownField.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  items: PropTypes.array,
  onChange: PropTypes.func
};

export default DropdownField;
