import PropTypes from 'prop-types';

const remoteDataShape = {
  'data': PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  'is': PropTypes.func,
  '@@values': PropTypes.array,
};

export default remoteDataShape;
