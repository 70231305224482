import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import * as p from 'rf-lib/routePaths';
import * as a from '../store/actions';

const ProtectedRoute = ({
  component: Component, requiredRole, path, ...rest
}) => {
  const authUser = useSelector(state => state.session.authUser);
  const isLoggedIn = authUser && authUser.emailVerified;

  const dispatch = useDispatch();

  if (!isLoggedIn) {
    dispatch(a.setRedirectPath(path));
  }

  return (
    <Route {...rest}
      render={props => {
        if (!isLoggedIn) {
          return (
            <Redirect
              to={{
                pathname: p.LOGIN,
                state: { url: props.match.url }
              }}
            />
          );
        }

        return <Component {...props} />;
      }}
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]).isRequired,
  requiredRole: PropTypes.string,
  path: PropTypes.string.isRequired,
  match: PropTypes.object,
};

export default ProtectedRoute;
