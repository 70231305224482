import { toAction } from 'rf-lib';
import * as t from './constants';
import { authUser } from '../../api';

export const sendPasswordResetEmail = dispatch =>
  email => {
    dispatch(toAction(t.PASSWORD_RESET));

    return authUser.sendPasswordResetEmail(email)
      .then(() => dispatch(toAction(t.PASSWORD_RESET_SUCCESS)))
      .catch(err => dispatch(toAction(t.PASSWORD_RESET_FAILURE, err)));
  };

export const resetState = dispatch => () => { dispatch(toAction(t.RESET_STATE)); };
