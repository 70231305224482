export const FETCH_GROUP_DETAILS = 'playerGroups/FETCH_GROUP_DETAILS';
export const FETCH_GROUP_DETAILS_FAILURE = 'playerGroups/FETCH_GROUP_DETAILS_FAILURE';
export const FETCH_GROUP_DETAILS_SUCCESS = 'playerGroups/FETCH_GROUP_DETAILS_SUCCESS';

export const DELETE_GROUP = 'playerGroups/DELETE_GROUP';
export const DELETE_GROUP_FAILURE = 'playerGroups/DELETE_GROUP_FAILURE';
export const DELETE_GROUP_SUCCESS = 'playerGroups/DELETE_GROUP_SUCCESS';

export const UPDATE_GROUP = 'playerGroups/UPDATE_GROUP';
export const UPDATE_GROUP_FAILURE = 'playerGroups/UPDATE_GROUP_FAILURE';
export const UPDATE_GROUP_SUCCESS = 'playerGroups/UPDATE_GROUP_SUCCESS';

export const DELETE_GROUP_IMAGE = 'playerGroups/DELETE_GROUP_IMAGE';
export const DELETE_GROUP_IMAGE_FAILURE = 'playerGroups/DELETE_GROUP_IMAGE_FAILURE';
export const DELETE_GROUP_IMAGE_SUCCESS = 'playerGroups/DELETE_GROUP_IMAGE_SUCCESS';
