import React from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { Theme } from 'rf-ui';
import { ProtectedRoute } from 'rf-comp';
import * as p from 'rf-lib/routePaths';
import * as pages from './pages';

const App = () => (
  <Theme>
    <Router hashType="slash">
      <Switch>
        <Route path={p.HOME} exact component={pages.Home} />
        <Route path={p.REGISTER} exact component={pages.Register} />
        <Route path={p.LOGIN} exact component={pages.Login} />
        <Route path={p.RESET_PASSWORD} exact component={pages.PasswordReset} />
        <ProtectedRoute path={p.DASHBOARD} exact component={pages.Dashboard} />
        <ProtectedRoute path={p.GAME} exact component={pages.Game} />
        <ProtectedRoute path={p.GROUPS} exact component={pages.PlayerGroups} />
        <ProtectedRoute path={p.GROUPS_EDIT} exact component={pages.EditPlayerGroup} />
        <ProtectedRoute path={p.NEW_GAME} exact component={pages.NewGame} />
        <ProtectedRoute path={p.NEW_GROUP} exact component={pages.NewPlayerGroup} />
        <ProtectedRoute path={p.GROUP_INVITE} exact component={pages.InviteToGroup} />
        <ProtectedRoute path={p.GROUP_INFO} exact component={pages.PlayerGroupInfo} />
        <ProtectedRoute path={p.PROFILE} exact component={pages.Profile} />
        <ProtectedRoute path={p.REGISTER_PAYMENT} exact component={pages.RegisterPayment} />
        <ProtectedRoute path={p.EDIT_GAME} exact component={pages.EditGame} />
        <ProtectedRoute path={p.AUTHORIZATIONS} exact component={pages.Authorizations} />
        <Route path={p.QUIT} exact component={pages.Quit} />
        <Route component={() => <span>not found</span>} />
      </Switch>
    </Router>
  </Theme>
);

export default App;
