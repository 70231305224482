/* eslint-disable no-console */
import { handleActions } from 'redux-actions';
import { RemoteData } from 'rf-lib';
import * as t from './constants';

const defaultGameRunnerState = {
  gameState: 'Not started',
  roundNumber: 0,
  lastShownAnimation: 0,
  isSpinning: false,
  remainingPlayers: [],
  ticketsLost: [],
  winnerList: []
};

const defaultState = {
  currentGameState: RemoteData.NotAsked,
  gamesState: RemoteData.NotAsked,
  gameRunnerState: defaultGameRunnerState,
  ticketsState: {},
  errors: [],
  fillTestDataRemoteData: RemoteData.NotAsked,
  groupDetailsRemoteData: RemoteData.NotAsked,
  updateGameRemoteData: RemoteData.NotAsked,
  resetGameRemoteData: RemoteData.NotAsked,
};

const getRemainingPlayers = (tickets, lastShownAnimation, ticketsLost) => {
  let gamePlayers = [];

  const adjustedTicketsLost = ticketsLost ? ticketsLost.slice(0, lastShownAnimation) : [];

  if (lastShownAnimation < adjustedTicketsLost.length) {
    adjustedTicketsLost.pop();
  }

  // eslint-disable-next-line no-return-assign
  const groupedLostTickets = adjustedTicketsLost.reduce((acc, value) => (
    // eslint-disable-next-line no-sequences
    acc[value] = (acc[value] || 0) + 1, acc), {});

  const gameTickets = tickets || [];

  gamePlayers = Object.keys(gameTickets || {}).reduce((result, playerId) => {
    const lostTickets = groupedLostTickets[playerId] || 0;

    if (lostTickets < gameTickets[playerId]) {
      result.push({
        playerId,
        tickets: (gameTickets[playerId] - lostTickets)
      });
    }
    return result;
  }, []);

  return gamePlayers;
};

// This is necessary because "true" arrays cannot be stored in firebase
// When there is only one element, that is returned as an object, while
// with two or more elements, we get an array.
const mapTickets = original => {
  let result = [];

  if (original) {
    if (Array.isArray(original)) {
      result = original.slice().filter(ticket => ticket != null);
    } else {
      result.push(original);
    }
  }

  return result;
};

const updateGameRunnerState = (state, payload) => {
  const ticketsLost = payload ? mapTickets(payload.ticketsLost) : [];
  let { lastShownAnimation } = state.gameRunnerState;
  const { gameState } = payload || { gameState: 'Not started' };
  const { tickets } = state.currentGameState.data;

  // Make sure lastShownAnimation is not past end of already known lost tickets
  if (lastShownAnimation > ticketsLost.length) {
    lastShownAnimation = ticketsLost.length;
  }

  if (gameState === 'Ended') {
    lastShownAnimation = ticketsLost.length;
  } else if (gameState === 'Started') {
    // If animation has not started, assume that we are refreshing mid-game.
    if (lastShownAnimation === 0 && ticketsLost.length > 0) {
      lastShownAnimation = ticketsLost.length - 1;
    }
  }

  const remainingPlayers =
    getRemainingPlayers(tickets, lastShownAnimation, ticketsLost);

  return {
    ...state.gameRunnerState,
    ...payload,
    lastShownAnimation,
    ticketsLost,
    remainingPlayers
  };
};

const updateCurrentGameState = (state, payload) => {
  const tickets = payload.tickets || [];

  const playersWithTickets = (Object.keys(tickets))
    .filter(x => {
      if (tickets[x] === 0) {
        return false;
      }
      return true;
    })
    .map(x => (
      {
        playerId: x,
        tickets: tickets[x]
      }
    ));

  return {
    ...state.currentGameState,
    ...payload,
    playersWithTickets,
  };
};

const updateLastShownAnimation = (state, lastShownAnimation) => {
  const { tickets } = state.currentGameState.data;
  const { ticketsLost } = state.gameRunnerState;
  const remainingPlayers =
    getRemainingPlayers(tickets, lastShownAnimation, ticketsLost);

  return {
    ...state.gameRunnerState,
    lastShownAnimation,
    isSpinning: false,
    remainingPlayers,
  };
};

const reducer = handleActions({
  [t.FETCH_GAME]: (state, action) => ({
    ...state, currentGameState: RemoteData.Loading
  }),

  [t.FETCH_GAME_FAILURE]: (state, action) => ({
    ...state, currentGameState: RemoteData.Failure(action.payload)
  }),

  [t.FETCH_GAME_SUCCESS]: (state, action) => ({
    ...state, currentGameState: RemoteData.Success(action.payload)
  }),

  [t.RESET_CURRENT_GAME]: (state, action) => defaultState,

  [t.UPDATE_GAME]: (state, action) => ({
    ...state, updateGameRemoteData: RemoteData.Loading
  }),

  [t.UPDATE_GAME_FAILURE]: (state, action) => ({
    ...state, updateGameRemoteData: RemoteData.Failure(action.payload)
  }),

  [t.UPDATE_GAME_SUCCESS]: (state, action) => ({
    ...state, updateGameRemoteData: RemoteData.Success(action.payload)
  }),

  [t.RESET_UPDATE_GAME_STATE]: (state, action) => ({
    ...state, updateGameRemoteData: RemoteData.NotAsked
  }),

  [t.FETCH_GAMES]: (state, action) => ({
    ...state, gamesState: RemoteData.Loading
  }),

  [t.FETCH_GAMES_FAILURE]: (state, action) => ({
    ...state, gamesState: RemoteData.Failure(action.payload)
  }),

  [t.FETCH_GAMES_SUCCESS]: (state, action) => ({
    ...state, gamesState: RemoteData.Success(action.payload)
  }),

  [t.START_GAME]: (state, action) => ({
    ...state, gameRunnerState: { ...state.gameRunnerState, gameState: 'Starter spill...' }
  }),

  [t.START_GAME_FAILURE]: (state, action) => ({
    ...state, errors: action.payload
  }),

  [t.START_GAME_SUCCESS]: (state, action) => ({
    ...state, gameRunnerState: { ...state.gameRunnerState, gameState: 'Started' }
  }),

  [t.GAME_LISTENER_FAILURE]: (state, action) => ({
    ...state, currentGameState: RemoteData.Failure(action.payload)
  }),

  [t.GAME_LISTENER_SUCCESS]: (state, action) => ({
    ...state, currentGameState: RemoteData.Success(updateCurrentGameState(state, action.payload))
  }),

  [t.GAMERUNNER_LISTENER_FAILURE]: (state, action) => ({
    ...state, errors: action.payload
  }),

  [t.GAMERUNNER_LISTENER_SUCCESS]: (state, action) => ({
    ...state, gameRunnerState: updateGameRunnerState(state, action.payload)
  }),

  [t.REMOVE_GAMERUNNER_LISTENER]: (state, action) => ({
    ...state,
    gameRunnerState: defaultGameRunnerState,
  }),

  [t.TICKETS_LISTENER_FAILURE]: (state, action) => ({
    ...state, errors: action.payload
  }),

  [t.TICKETS_LISTENER_SUCCESS]: (state, action) => ({
    ...state, ticketsState: { ...state.ticketsState, ...action.payload }
  }),

  [t.REMOVE_TICKETS_LISTENER]: (state, action) => ({
    ...state,
    ticketsState: {},
  }),

  [t.FILL_TEST_DATA]: (state, action) => ({
    ...state, fillTestDataRemoteData: RemoteData.Loading
  }),

  [t.FILL_TEST_DATA_FAILURE]: (state, action) => ({
    ...state, fillTestDataRemoteData: RemoteData.Failure(action.payload)
  }),

  [t.FILL_TEST_DATA_SUCCESS]: (state, action) => ({
    ...state, fillTestDataRemoteData: RemoteData.Success(action.payload)
  }),

  [t.RESET_FILL_TEST_DATA_REMOTEDATA]: (state, action) => ({
    ...state, fillTestDataRemoteData: RemoteData.NotAsked
  }),

  [t.RESET_GAME]: (state, action) => ({
    ...state, resetGameRemoteData: RemoteData.Loading
  }),

  [t.RESET_GAME_FAILURE]: (state, action) => ({
    ...state, resetGameRemoteData: RemoteData.Failure(action.payload)
  }),

  [t.RESET_GAME_SUCCESS]: (state, action) => ({
    ...state, resetGameRemoteData: RemoteData.Success(action.payload)
  }),

  [t.RESET_RESET_GAME_REMOTEDATA]: (state, action) => ({
    ...state, resetGameRemoteData: RemoteData.NotAsked
  }),

  [t.FETCH_GROUP_DETAILS]: (state, action) => ({
    ...state, groupDetailsRemoteData: RemoteData.Loading
  }),

  [t.FETCH_GROUP_DETAILS_FAILURE]: (state, action) => ({
    ...state, groupDetailsRemoteData: RemoteData.Failure(action.payload)
  }),

  [t.FETCH_GROUP_DETAILS_SUCCESS]: (state, action) => ({
    ...state, groupDetailsRemoteData: RemoteData.Success(action.payload)
  }),

  [t.SET_CURRENT_ANIMATION]: (state, action) => ({
    ...state,
    gameRunnerState: updateLastShownAnimation(state, action.payload)
  }),
  [t.SET_IS_SPINNING]: (state, action) => ({
    ...state,
    gameRunnerState: {
      ...state.gameRunnerState,
      isSpinning: true,
    }
  }),
}, defaultState);

export default reducer;
