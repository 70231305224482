/* eslint-disable no-console */
import React, { useEffect } from 'react';
import Two from 'twojs-ts';
import {
  getWheelElements,
  drawWheel,
  drawTicker,
} from './wheelOfTicketsHelper';

const ratios = {
  tickerOuterRadius: 0.06, // of radius
  tickerInnerRadius: 0.03, // of radius
  tickerLength: 2, // of ticker radius
  textSize: 0.07, // of radius
  edgeDist: 0.14, // of radius
};

let parameters = {
  angularFriction: 12, // Higher value keeps the wheel spinning longer
  wheelRadius: 150,
  tickerAngle: 40,
  animationTime: 2, // Wedge removal animation time in seconds
};

const WheelOfTickets = inputPlayers => {
  const joinedPlayers = inputPlayers;

  let wheelElements = {
    arcs: [],
    bars: [],
    names: []
  };

  useEffect(
    () => {
      // Update the document title using the browser API
      const elem = document.getElementById('rotatingWheel');
      elem.innerHTML = '';

      const container = document.getElementById('wheelContainer');
      let width = window.innerWidth - parseInt(container.offsetLeft);
      let height = width;

      width = Math.min(width, 750); // max size
      width = Math.max(width, 420); // min size
      height = Math.min(height, 750);
      height = Math.max(height, 420);

      const radius = ((Math.min(width, height)) / 2) * 0.85;

      // update wheel radius
      parameters = { ...parameters, wheelRadius: radius };

      const two = new Two({
        width,
        height,
        type: Two.Types.webgl
      }).appendTo(elem);

      const wheelGroup = two.makeGroup();
      drawTicker(parameters, ratios, two);

      wheelElements = getWheelElements(joinedPlayers, parameters, ratios, two);
      wheelGroup.add(drawWheel(wheelElements, joinedPlayers, two));
      wheelGroup.translation.set(two.width / 2, two.height / 2);

      two.update();
    },
    [joinedPlayers]
  );

  const rotatingWheel = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  };

  return (
    <div id="rotatingWheel" style={rotatingWheel} />
  );
};

export default WheelOfTickets;
