import React from 'react';
import Two from 'twojs-ts';
import {
  animateWedgeRemoval,
  getWheelElements,
  drawWheel,
  drawTicker,
  getCurrentWedgeSize,
  getNextWedgeSize,
  getNumTickets
} from './wheelHelpers';

let rotationAngle = 0;
let targetAngle = 0;
let angleDelta;
let targetAngleAfterRemoval = 0;
let currentWedgeSize = 0;
let nextWedgeSize = 0;
const maxAngleDelta = 1/4;
const minAngleDelta = 1/500.0;
let wedgeSizePct = 1.0;
let isSpinning = false;
let isWedgeRemoved = false;
let isWedgeRemoving = false;
let currentRoundNumber = 0;

let rotationOffset;
const rotationSmoothing = 20;
let animatedText;

const animatedLostNames = [];
const gravity = new Two.Vector(0, 0.1);

let selectedTicketIndex;
let players = [];
let isTicketHidden = [];
let two;

const ratios = {
  tickerOuterRadius: 0.06, // of radius
  tickerInnerRadius: 0.03, // of radius
  tickerLength: 2, // of ticker radius
  textSize: 0.07, // of radius
  edgeDist: 0.14, // of radius
};

let parameters = {
  wheelRadius: 150,
  tickerAngle: 40,
  animationTime: 1.5, // Wedge removal animation time in seconds
};

let wheelElements = {
  arcs: [],
  bars: [],
  names: []
};

const addLostTicketName = msg => {
  const x = ((Math.random() * two.width) / 2) + (two.width / 4);
  const y = 50;

  const text = two.makeText(msg, x, y);
  text.fill = '#FFFFFF';
  text.size = parameters.wheelRadius * ratios.textSize * 1.4;

  text.velocity = new Two.Vector();
  text.velocity.x = 5 * (Math.random() - 0.5);
  text.velocity.y = -1;
  text.velocity.r = Math.PI / 50;

  animatedLostNames.push(text);
};

const getPlayerName = playerIndex => {
  if (players) {
    return players[playerIndex].playerName;
  }

  return '';
};

const WheelOfFortune = (inputPlayers, onAnimationFinished) => {
  if (players.length === 0) {
    players = inputPlayers.map(player => (
      {
        ...player,
        activeTickets: player.tickets,
      }
    ));

    isTicketHidden = Array(getNumTickets(players)).fill(false);
  }

  if (!two) {
    // Update the document title using the browser API
    const elem = document.getElementById('rotatingWheel');
    elem.innerHTML = '';

    const container = document.getElementById('wheelContainer');
    let width = window.innerWidth - parseInt(container.offsetLeft);
    let height = width;

    // Set min and max size for the rotating wheel canvas
    width = Math.min(width, 750); // max size
    width = Math.max(width, 420); // min size
    height = Math.min(height, 750);
    height = Math.max(height, 420);

    const radius = ((Math.min(width, height)) / 2) * 0.85;

    // update wheel radius
    parameters = { ...parameters, wheelRadius: radius };

    two = new Two({
      width,
      height,
      type: Two.Types.webgl
    }).appendTo(elem);

    wheelElements = getWheelElements(players, parameters, ratios, two);

    const wheelGroup = two.makeGroup();
    wheelGroup.add(drawWheel(wheelElements, players, two));

    // Translate an outer group, to allow rotation around origin inside the inner group
    wheelGroup.translation.set(two.width / 2, two.height / 2);
    let tickerGroup = drawTicker(parameters, ratios, two);

    // Bind a function to scale and rotate the group
    // to the animation loop.
    two.bind('update', frameCount => {
      // Rotate wheel and draw ticket
      if (isSpinning) {
        angleDelta = (targetAngle - rotationAngle) / 30;

        angleDelta = Math.min(angleDelta, maxAngleDelta);
        angleDelta = Math.max(angleDelta, minAngleDelta);

        if (rotationAngle < targetAngle) {
          rotationAngle += angleDelta;
        } else {
          angleDelta = 0;
          isWedgeRemoving = true;
          isSpinning = false;
        }
      }

      // Remove ticket from wheel after being drawn
      if (isWedgeRemoving) {
        if (isWedgeRemoved === false) {
          if (wedgeSizePct > 0) {
            if (two.timeDelta) {
              wedgeSizePct -= (two.timeDelta) / (parameters.animationTime * 1000);
            } else {
              wedgeSizePct -= 0.05;
            }

            wedgeSizePct = Math.max(wedgeSizePct, 0);

            animateWedgeRemoval(wheelElements, players,
              wedgeSizePct, selectedTicketIndex, isTicketHidden, parameters);

            // Adjust rotation to new wedge size
            rotationOffset = targetAngleAfterRemoval - rotationAngle;

            if (Math.abs(rotationOffset) > minAngleDelta) {
              rotationAngle += rotationOffset / rotationSmoothing;
            }
          } else {
            isWedgeRemoved = true;
            rotationAngle = targetAngleAfterRemoval;
            wedgeSizePct = 1.0;
          }
        } else {
          isWedgeRemoving = false;
          isWedgeRemoved = false;
          players[selectedTicketIndex.playerIndex].activeTickets -= 1;
          isTicketHidden[selectedTicketIndex.absoluteTicketIndex] = true;
          addLostTicketName(getPlayerName(selectedTicketIndex.playerIndex));
          onAnimationFinished(currentRoundNumber);
        }
      }

      // Spin losing ticket owner's name out from wheel
      wheelGroup.rotation = rotationAngle;
      for (let i = 0; i < animatedLostNames.length; i += 1) {
        animatedText = animatedLostNames[i];
        animatedText.translation.addSelf(animatedText.velocity);
        animatedText.rotation += animatedText.velocity.r;

        animatedText.velocity.addSelf(gravity);
        if (animatedText.velocity.y > 0 && animatedText.translation.y > two.height) {
          two.scene.remove(animatedText);
          animatedLostNames.splice(i, 1);
        }
      }

      tickerGroup.remove();
      tickerGroup = drawTicker(parameters, ratios, two);
    }).play();
  }

  currentWedgeSize = getCurrentWedgeSize(players);
  nextWedgeSize = getNextWedgeSize(players);

  const rotatingWheel = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  };

  return (
    <div id="rotatingWheel" style={rotatingWheel} />
  );
};

const getSelectedTicketIndex = playerId => {
  let visibleTicketIndex = -1;
  let absoluteTicketIndex = -1;
  let playerIndex = -1;
  let found = false;

  while (!found) {
    playerIndex += 1;
    const player = players[playerIndex];

    if (!player) {
      // eslint-disable-next-line no-console
      console.log(playerIndex);
    }

    found = (player.playerId===playerId);

    absoluteTicketIndex += found ? player.activeTickets : player.tickets;
    visibleTicketIndex += player.activeTickets;
  }

  return { absoluteTicketIndex, visibleTicketIndex, playerIndex };
};

const spinWheel = (playerId, roundNumber) => {
  if (!isSpinning) {
    selectedTicketIndex = getSelectedTicketIndex(playerId);
    const { visibleTicketIndex } = selectedTicketIndex;

    if (visibleTicketIndex >=0) {
      const rotations = 3;
      const rotationOffsetFromZero = rotationAngle
        - Math.floor(rotationAngle / (2 * Math.PI)) * 2 * Math.PI;
      const centerOfSelectedTicketAngle = rotationOffsetFromZero
        + (visibleTicketIndex + 0.5) * currentWedgeSize;

      targetAngle = rotationAngle + Math.PI * 2 * rotations - centerOfSelectedTicketAngle;

      // Add an offset for increased suspense...
      targetAngle += (Math.random() - 0.5) * currentWedgeSize;

      const startOfNextTicketAngle = rotationOffsetFromZero + (visibleTicketIndex * nextWedgeSize);
      targetAngleAfterRemoval = rotationAngle + Math.PI * 2 * rotations - startOfNextTicketAngle;
      isSpinning = true;
      currentRoundNumber = roundNumber;
    }
  }
};

export default { WheelOfFortune, spinWheel };
