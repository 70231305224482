import React from 'react';
import styled from 'styled-components';
import { get } from 'rf-lib';
import { Link } from 'react-router-dom';
import * as paths from 'rf-lib/routePaths';
import Logo from './Logo';

const Menu = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  background: ${get('colorBackgroundSecondary')};
  padding: ${get('spacingMd')};

  a:not(:first-child) {
    margin-top: ${get('spacingXs')};
  }
`;

const MenuItem = styled(Link)`
  margin-right: 1rem;
`;

const ItemsGroup = styled.div`
  display: flex;
  align-items: center;

  a:not(:first-child) {
    margin-left: 1rem;
  }
`;

const MenuWrapper = () => (
  <Menu>
    <ItemsGroup>
      <Link to="/">
        <Logo />
      </Link>
      <MenuItem to={paths.DASHBOARD}>Mine spill</MenuItem>
      <MenuItem to={paths.GROUPS}>Spillergrupper</MenuItem>
      <MenuItem to={paths.PROFILE}>Min side</MenuItem>
    </ItemsGroup>
    <MenuItem to={paths.QUIT}>Logg ut</MenuItem>
  </Menu>
);

export default MenuWrapper;
