import { handleActions } from 'redux-actions';
import { RemoteData } from 'rf-lib';
import * as t from './constants';

const defaultState = {
  groupDetailsState: RemoteData.NotAsked,
  deleteMemberState: RemoteData.NotAsked,
  isPlayerMemberState: RemoteData.NotAsked
};

const reducer = handleActions({
  [t.FETCH_GROUP_DETAILS]: (state, action) => ({
    ...state, groupDetailsState: RemoteData.Loading
  }),

  [t.FETCH_GROUP_DETAILS_FAILURE]: (state, action) => ({
    ...state, groupDetailsState: RemoteData.Failure(action.payload)
  }),

  [t.FETCH_GROUP_DETAILS_SUCCESS]: (state, action) => ({
    ...state, groupDetailsState: RemoteData.Success(action.payload)
  }),

  [t.IS_PLAYER_MEMBER]: (state, action) => ({
    ...state, isPlayerMemberState: RemoteData.Loading
  }),

  [t.IS_PLAYER_MEMBER_FAILURE]: (state, action) => ({
    ...state, isPlayerMemberState: RemoteData.Failure(action.payload)
  }),

  [t.IS_PLAYER_MEMBER_SUCCESS]: (state, action) => ({
    ...state, isPlayerMemberState: RemoteData.Success(action.payload)
  }),

  [t.DELETE_MEMBER]: (state, action) => ({
    ...state, deleteMemberState: RemoteData.Loading
  }),

  [t.DELETE_MEMBER_FAILURE]: (state, action) => ({
    ...state, deleteMemberState: RemoteData.Failure(action.payload)
  }),

  [t.DELETE_MEMBER_SUCCESS]: (state, action) => ({
    ...state, deleteMemberState: RemoteData.Success(action.payload)
  }),

  [t.DELETE_GAME]: (state, action) => ({
    ...state, deleteGameState: RemoteData.Loading
  }),

  [t.DELETE_GAME_FAILURE]: (state, action) => ({
    ...state, deleteGameState: RemoteData.Failure(action.payload)
  }),

  [t.DELETE_GAME_SUCCESS]: (state, action) => ({
    ...state, deleteGameState: RemoteData.Success(action.payload)
  }),
}, defaultState);

export default reducer;
