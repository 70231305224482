import firebase from '../firebase/firebase';

export const playerTransactionsRef = (groupid, uid) => firebase.db.ref(`groupTransactions/${groupid}/${uid}`);

export const transactionRef = (groupid, uid, gameId) => firebase.db.ref(`groupTransactions/${groupid}/${uid}/${gameId}`);

export const onTransactionsChange = (groupId, uid, successCallback, errorCallback) => {
  playerTransactionsRef(groupId, uid).on('value', successCallback, errorCallback);
};

export const removeTransactionsListener =
 (groupId, playerId) => playerTransactionsRef(groupId, playerId).off();

export const fetchPlayerTransactions =
 (groupId, playerId) => playerTransactionsRef(groupId, playerId)
   .once('value')
   .then(snapshot => snapshot.val());

export const calculateBalance = transactions => {
  const sum = { amount: 0 };
  if (transactions != null) {
    const values = Object.values(transactions);
    values.forEach(transaction => {
      switch (transaction.type) {
        case 'purchase':
          sum.amount -= Number(transaction.amount);
          break;
        case 'payment':
          sum.amount += Number(transaction.amount);
          break;
        default:
          break;
      }
    });
  }
  return sum;
};

export const getPlayerBalance = (groupId, playerId) => fetchPlayerTransactions(groupId, playerId)
  .then(transactions => calculateBalance(transactions));

export const createTransaction = (groupId, playerId, transaction) => (
  playerTransactionsRef(groupId, playerId).push(transaction)
);

export const createPurchaseTransaction = (groupId, playerId, gameId, transaction) => (
  transactionRef(groupId, playerId, gameId).push(transaction)
);
