import { handleActions } from 'redux-actions';
import { RemoteData } from 'rf-lib';
import * as t from './constants';

const defaultState = {
  playerGroupsState: RemoteData.NotAsked
};

const reducer = handleActions({
  [t.FETCH_PLAYER_GROUPS]: (state, action) => ({
    ...state, playerGroupsState: RemoteData.Loading
  }),

  [t.FETCH_PLAYER_GROUPS_FAILURE]: (state, action) => ({
    ...state, playerGroupsState: RemoteData.Failure(action.payload)
  }),

  [t.FETCH_PLAYER_GROUPS_SUCCESS]: (state, action) => ({
    ...state, playerGroupsState: RemoteData.Success(action.payload)
  }),

  [t.RESET_STATE]: state => ({
    ...state, playerGroupsState: RemoteData.NotAsked
  })
}, defaultState);

export default reducer;
