import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { RemoteData } from 'rf-comp';
import { Button } from 'rf-ui';
import * as a from './actions';

const ResetGame = ({ gameId, resetGame, ...props }) => {
  useEffect(
    () => {
      props.resetResetGameRemoteData();
    }, []
  );

  return (
    <RemoteData type={props.resetGameRemoteData}
          notAsked={() => <Button title="Tilbakestill spill" onClick={() => resetGame(gameId)} />}
          loading={() => <span>Deleting testdata...</span>}
          failure={err => <span>Feil under skriving: {err.message}</span>}
          success={() => <span>Spillet er resatt!</span>}
          />
  );
};

ResetGame.propTypes = {
  gameId: PropTypes.string.isRequired,
  resetGame: PropTypes.func.isRequired,
  resetGameRemoteData: PropTypes.object.isRequired,
  resetResetGameRemoteData: PropTypes.func.isRequired,
};

const mapStateToProps = ({ games }) => ({
  resetGameRemoteData: games.resetGameRemoteData,
});

const mapDispatchToProps = dispatch => ({
  resetGame: a.resetGame(dispatch),
  resetResetGameRemoteData: a.resetResetGameRemoteData(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetGame);
