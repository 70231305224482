import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useField } from 'formik';
import { get } from 'rf-lib';

const Input = styled.input`
  ${props =>
    props.err
      ? css`
      border: 5px solid red;
      `
      : css`
      border-radius: 3px;
    `};
    ${props => props.disabled
    ? css`
    background: lightgrey;
    `
    : css`
    background: '';
    `};
  border-radius: 3px;
  padding: 0.6rem;
  height: auto;
`;

const Error = styled.label`
  color: red;
  height: auto;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: ${get('spacingSm')} 0 ${get('spacingMd')} 0;
`;

const FormField = ({
  title, type = 'text', disabled = false, ...props
}) => {
  const [field, meta] = useField(props);
  const touchedAndError = meta.touched && meta.error;
  return (
    <Container>
      <label htmlFor={props.id}>{title}</label>
      <Input className="text-input" type={type} err={touchedAndError} {...field} {...props} disabled={disabled} />
      {touchedAndError ? (
        <Error className="error">{meta.error}</Error>
      ) : null}
    </Container>
  );
};

FormField.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool
};

export default FormField;
