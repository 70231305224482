export const FETCH_GAME = 'games/FETCH_GAME';
export const FETCH_GAME_SUCCESS = 'games/FETCH_GAME_SUCCESS';
export const FETCH_GAME_FAILURE = 'games/FETCH_GAME_FAILURE';

export const UPDATE_GAME = 'games/UPDATE_GAME';
export const UPDATE_GAME_SUCCESS = 'games/UPDATE_GAME_SUCCESS';
export const UPDATE_GAME_FAILURE = 'games/UPDATE_GAME_FAILURE';

export const RESET_UPDATE_GAME_STATE = 'games/RESET_UPDATE_GAME_STATE';

export const FETCH_GAMES = 'games/FETCH_GAMES';
export const FETCH_GAMES_SUCCESS = 'games/FETCH_GAMES_SUCCESS';
export const FETCH_GAMES_FAILURE = 'games/FETCH_GAMES_FAILURE';

export const START_GAME = 'games/START_GAME';
export const START_GAME_SUCCESS = 'games/START_GAME_SUCCESS';
export const START_GAME_FAILURE = 'games/START_GAME_FAILURE';

export const ADD_GAME_LISTENER = 'games/ADD_GAME_LISTENER';
export const GAME_LISTENER_SUCCESS = 'games/GAME_LISTENER_SUCCESS';
export const GAME_LISTENER_FAILURE = 'games/GAME_LISTENER_FAILURE';

export const REMOVE_GAME_LISTENER = 'games/REMOVE_GAME_LISTENER';

export const ADD_GAMERUNNER_LISTENER = 'games/ADD_GAMERUNNER_LISTENER';
export const GAMERUNNER_LISTENER_SUCCESS = 'games/GAMERUNNER_LISTENER_SUCCESS';
export const GAMERUNNER_LISTENER_FAILURE = 'games/GAMERUNNER_LISTENER_FAILURE';

export const REMOVE_GAMERUNNER_LISTENER = 'games/REMOVE_GAMERUNNER_LISTENER';

export const ADD_TICKETS_LISTENER = 'games/ADD_TICKETS_LISTENER';
export const TICKETS_LISTENER_SUCCESS = 'games/TICKETS_LISTENER_SUCCESS';
export const TICKETS_LISTENER_FAILURE = 'games/TICKETS_LISTENER_FAILURE';

export const REMOVE_TICKETS_LISTENER = 'games/REMOVE_TICKETS_LISTENER';

export const BUY_TICKET = 'games/BUY_TICKET';
export const SELL_TICKET = 'games/SELL_TICKET';

export const FILL_TEST_DATA = 'games/FILL_TEST_DATA';
export const FILL_TEST_DATA_SUCCESS = 'games/FILL_TEST_DATA_SUCCESS';
export const FILL_TEST_DATA_FAILURE = 'games/FILL_TEST_DATA_FAILURE';
export const RESET_FILL_TEST_DATA_REMOTEDATA = 'games/RESET_FILL_TEST_DATA_REMOTEDATA';

export const FETCH_GROUP_DETAILS = 'games/FETCH_GROUP_DETAILS';
export const FETCH_GROUP_DETAILS_SUCCESS = 'games/FETCH_GROUP_DETAILS_SUCCESS';
export const FETCH_GROUP_DETAILS_FAILURE = 'games/FETCH_GROUP_DETAILS_FAILURE';

export const RESET_GAME = 'games/RESET_GAME';
export const RESET_GAME_SUCCESS = 'games/RESET_GAME_SUCCESS';
export const RESET_GAME_FAILURE = 'games/RESET_GAME_FAILURE';
export const RESET_RESET_GAME_REMOTEDATA = 'games/RESET_RESET_GAME_REMOTEDATA';

export const SET_CURRENT_ANIMATION = 'games/SET_CURRENT_ANIMATION';

export const SET_IS_SPINNING = 'games/SET_IS_SPINNING';

export const RESET_CURRENT_GAME = 'RESET_CURRENT_GAME';
