import { toAction } from 'rf-lib';
import { groups } from '../../../api';
import * as t from './constants';

export const createGroupAction = dispatch =>
  (userId, groupName, groupDescription, groupImage) => {
    dispatch(toAction(t.CREATE_GROUP));

    return groups.createGroup(userId, groupName, groupDescription, groupImage)
      .then(groupsData => dispatch(toAction(t.CREATE_GROUP_SUCCESS, groupsData)))
      .catch(err => dispatch(toAction(t.CREATE_GROUP_FAILURE, err)));
  };

export const resetState = dispatch => () => { dispatch(toAction(t.RESET_STATE)); };
