import { toAction } from 'rf-lib';
import * as t from './constants';
import { SET_REDIRECT_PATH } from '../../store/actions/constants';
import { authUser } from '../../api';

export const signInWithEmail = dispatch =>
  (email, password) => {
    dispatch(toAction(t.LOGIN));

    return authUser.signIn(email, password)
      .then(user => dispatch(toAction(t.LOGIN_SUCCESS, user)))
      .catch(err => dispatch(toAction(t.LOGIN_FAILURE, err)));
  };

export const clearRedirectPath = dispatch =>
  () => dispatch(toAction(SET_REDIRECT_PATH, null));

export const resetState = dispatch => () => { dispatch(toAction(t.RESET_STATE)); };

