import { RemoteData } from 'rf-lib';
import * as t from '../actions/constants';

const INITIAL_STATE = {
  authUser: JSON.parse(localStorage.getItem('authUser')),
  failure: '',
  redirectPath: null,
  playersRemoteData: RemoteData.NotAsked,
};

const applySignOut = (state, action) => ({
  ...state,
  authUser: null,
  failure: '',
});

const applySetAuthUser = (state, { payload }) => ({
  ...state,
  authUser: payload,
});

const applyUpdatePlayer = (state, { payload }) => ({
  ...state,
  authUser: {
    ...state.authUser,
    email: payload.email,
    displayName: payload.displayName,
    color: payload.color,
    ...(payload.imageUrl) && { imageUrl: payload.imageUrl }
  },
});

const updateUserRoles = (state, { payload }) => ({
  ...state,
  authUser: {
    ...state.authUser,
    roles: payload,
  },
});

const applyFailure = (state, { payload }) => ({
  ...state,
  failure: payload.message
});

function session(state = INITIAL_STATE, action) {
  switch (action.type) {
    case t.SIGN_OUT:
      return applySignOut(state, action);
    case t.SIGN_OUT_FAILURE:
      return applyFailure(state, action);
    case t.SET_AUTH_USER:
      return applySetAuthUser(state, action);
    case t.UPDATE_PLAYER_SUCCESS:
      return applyUpdatePlayer(state, action);
    case t.UPDATE_PLAYER_FAILURE:
      return applyFailure(state, action);
    case t.SET_REDIRECT_PATH:
      return ({ ...state, redirectPath: action.payload });
    case t.FETCH_PLAYERS:
      return ({
        ...state,
        playersRemoteData: RemoteData.Loading,
      });
    case t.FETCH_PLAYERS_SUCCESS:
      return ({
        ...state,
        playersRemoteData: RemoteData.Success(action.payload),
      });
    case t.FETCH_PLAYERS_FAILURE:
      return ({
        ...state,
        playersRemoteData: RemoteData.Failure(action.payload),
      });
    case t.AUTHORIZATIONS_LISTENER_SUCCESS:
      return updateUserRoles(state, action);
    case t.AUTHORIZATIONS_LISTENER_FAILURE:
    default:
      return state;
  }
}

export default session;
