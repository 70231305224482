/* eslint-disable object-curly-newline */
import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { variant as variantFn, get } from 'rf-lib';

const background = variantFn({
  primary: 'linear-gradient(#11a598, #3f79c2)',
  secondary: 'linear-gradient(#a641f5, #891292)'
});

const Button = styled.button`
  ${props =>
    props.disabled
      ? css`
      color: lightgrey;
      cursor: default;
      `
      : css`
      color: white;
      cursor: pointer;
    `};
  font-size: ${get('fontTextSm')};
  text-decoration: none;
  margin: 1px;
  border: none;
  padding: ${get('spacingXs')};
  border-radius: ${get('spacingXs')};
  text-align: center;
  background: ${background};
`;

const ButtonWrapper = ({ title, onClick, variant, ...props }) =>
  (
    <Button variant={variant} onClick={onClick} {...props}>
      {title}
    </Button>
  );

ButtonWrapper.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary']),
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

ButtonWrapper.defaultProps = {
  variant: 'primary',
};

export default ButtonWrapper;
