import {
  applyMiddleware, compose, createStore, combineReducers
} from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import * as a from './store/actions';

const configureStore = reducers => {
  const middleware = [
    thunk,
    logger
  ];

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];

  const isDevelopment = process.env.NODE_ENV === 'development';
  if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
    /* eslint-disable-next-line */
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
  }

  const rootReducer = combineReducers(reducers);

  const store = createStore(
    rootReducer,
    {},
    compose(applyMiddleware(...middleware), ...enhancers)
  );

  store.dispatch(a.verifyAuth());

  return store;
};

export default configureStore;
