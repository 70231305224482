// The user should be able to pick a color themselves, this is just a temp solution
const generateColorFromName = name => {
  const nameSum = name.split('').map(x =>
    x.charCodeAt(0))
    .reduce((acc, num) => acc + num, 0);

  const digSum = n => {
    if (n === 0) return 0;

    return (n % 9 === 0) ? 9 : (n % 9);
  };

  const index = digSum(nameSum);
  const colors = ['#FA8072', '#FF69B4', '#FFA500', '#FFD700', '#DDA0DD', '#FF00FF',
    '#663399', '#7B68EE', '#32CD32', '#3CB371'];

  return colors[index];
};

export default generateColorFromName;
