import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { RemoteData } from 'rf-comp';
import {
  FormField,
  H1,
  Page,
  Button,
  ImageUpload
} from 'rf-ui';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import * as a from './actions';

const OnSuccess = ({ history, groupId }) =>
  (
    <div>
      <H1>Gruppe lagret!</H1>
      <Button title="Gå til gruppe" onClick={() => history.push(`/groups/info/${groupId}`)} />
      <Button title="Gå tilbake til gruppeoversikt" onClick={() => history.push('/groups/')} />
    </div>
  );

OnSuccess.propTypes = {
  history: PropTypes.object,
  groupId: PropTypes.string,
};

const Schema = Yup.object({
  groupName: Yup.string()
    .trim()
    .max(40, 'Gruppenavnet er for langt, må være mellom 5-40 bokstaver')
    .min(5, 'Gruppenavnet er for kort, må være mellom 5-40 bokstaver')
    .required('Required'),
  groupDescription: Yup.string()
    .trim()
    .min(5, 'Gruppebeskrivelsen er for kort, må være mellom 5-50 bokstaver')
    .max(50, 'Gruppebeskrivelsen er for lang, må være mellom 5-50 bokstaver')
    .required('Required')
});

const FormSchema = ({ authUser, history, ...props }) => {
  const [groupImage, setGroupImage] = useState('');

  const handleImageAsFile = file => {
    setGroupImage(file);
  };

  return (
    <Formik
        initialValues={{ groupName: '', groupDescription: '' }}
        validationSchema={Schema}
        onSubmit={values => {
          props.createGroup(authUser.uid, values.groupName.trim(),
            values.groupDescription.trim(), groupImage);
        }}
      >
      <Form>
        <FormField
            name="groupName"
            title="Spillergruppens navn"
        />
        <FormField
            name="groupDescription"
            title="Beskrivelse"
        />
        <ImageUpload title="Velg gruppebilde" onImage={file => handleImageAsFile(file)} />
        <Button title="Opprett" type="submit" />
        <Button title="Tilbake" onClick={() => history.push('/groups/')} />
      </Form>
    </Formik>
  );
};

FormSchema.propTypes = {
  authUser: PropTypes.object.isRequired,
  history: PropTypes.object,
  createGroup: PropTypes.func,
};

const GroupForm = ({ authUser, ...props }) => {
  const history = useHistory();
  useEffect(() => props.resetState(), []);
  return (
    <>
      <h1>Lag en ny spillergruppe og inviter dine kollegaer og venner!</h1>
      <RemoteData
          type={props.newPlayerGroupState}
          notAsked={() => <FormSchema authUser={authUser} history={history} {...props} />}
          loading={() => <span>Lagrer gruppe...</span>}
          failure={err => <span>Feil: {err.message}</span>}
          success={group => <OnSuccess groupId={group.id} history={history} />}
        />
    </>
  );
};

GroupForm.propTypes = {
  authUser: PropTypes.object.isRequired,
  createGroup: PropTypes.func.isRequired,
  newPlayerGroupState: PropTypes.object.isRequired,
  resetState: PropTypes.func.isRequired,
};

const NewPlayerGroup = ({ authUser, ...props }) => (
  <Page>
    <H1>Lag ny spillergruppe</H1>
    {authUser ? (<GroupForm authUser={authUser} {...props} />) : (<></>) }
  </Page>
);

NewPlayerGroup.propTypes = {
  authUser: PropTypes.object,
  createGroup: PropTypes.func.isRequired,
  newPlayerGroupState: PropTypes.object.isRequired,
  resetState: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  createGroup: a.createGroupAction(dispatch),
  resetState: a.resetState(dispatch),
});

const mapStateToProps = ({ session, newPlayerGroup }) => ({
  authUser: session.authUser,
  newPlayerGroupState: newPlayerGroup.newPlayerGroupState,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewPlayerGroup);
