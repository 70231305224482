export const BALANCE_LISTENER = 'balance/BALANCE_LISTENER';
export const BALANCE_LISTENER_SUCCESS = 'balance/BALANCE_LISTENER_SUCCESS';
export const BALANCE_LISTENER_FAILURE = 'balance/BALANCE_LISTENER_FAILURE';

export const REMOVE_BALANCE_LISTENER = 'balance/REMOVE_BALANCE_LISTENER';

export const FETCH_TRANSACTIONS = 'balance/FETCH_TRANSACTIONS';
export const FETCH_TRANSACTIONS_SUCCESS = 'balance/FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_FAILURE = 'balance/FETCH_TRANSACTIONS_FAILURE';

export const FETCH_PLAYERS = 'balance/FETCH_PLAYERS';
export const FETCH_PLAYERS_SUCCESS = 'balance/FETCH_PLAYERS_SUCCESS';
export const FETCH_PLAYERS_FAILURE = 'balance/FETCH_PLAYERS_FAILURE';

export const CREATE_TRANSACTION = 'balance/CREATE_TRANSACTION';
export const CREATE_TRANSACTION_SUCCESS = 'balance/CREATE_TRANSACTION_SUCCESS';
export const CREATE_TRANSACTION_FAILURE = 'balance/CREATE_TRANSACTION_FAILURE';

export const RESET_PLAYER_TRANSACTION_STATE = 'balance/RESET_PLAYER_TRANSACTION_STATE';
