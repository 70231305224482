import React from 'react';
import PropTypes from 'prop-types';
import { remoteDataShape } from 'rf-lib';

const RemoteData = ({
  type, notAsked, loading, failure, success
}) =>
  type.cata({
    NotAsked: notAsked || (() => null),
    Loading: loading || (() => <span>Loading...</span>),
    Failure: failure,
    Success: success
  });

RemoteData.propTypes = {
  type: PropTypes.shape(remoteDataShape).isRequired,
  notAsked: PropTypes.func,
  loading: PropTypes.func,
  failure: PropTypes.func.isRequired,
  success: PropTypes.func.isRequired
};

export default RemoteData;
