import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { RemoteData } from 'rf-comp';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FormField, FormPage, Button } from 'rf-ui';
import * as a from './actions';

const RESET_EMAIL_SENT_MESSAGE = 'Du vil straks motta en epost med lenke til å nullstille passord.';

const ResetForm = ({ sendResetEmail }) => (
  <Formik initialValues={{ email: '' }}
    validationSchema={Yup.object({
      email: Yup.string()
        .email('Ugyldig e-postadresse')
        .required('E-post må være fylt ut')
    })}
  onSubmit={values => { sendResetEmail(values.email); }}
  validateOnChange={false}
  validateOnBlur={false}
  >
    <Form>
      <FormField
        autoFocus
        name="email"
        title="E-post"
      />
      <Button title="Tilbakestill passord" type="submit" />
    </Form>
  </Formik>
);

ResetForm.propTypes = {
  sendResetEmail: PropTypes.func.isRequired,
};

const PasswordResetPage = ({
  sendResetEmail, passwordResetState, resetState, ...props
}) => {
  const history = useHistory();
  useEffect(() => resetState(), []);
  return (
    <FormPage title="Glemt passord">
      <RemoteData
        type={passwordResetState}
        notAsked={() => <ResetForm sendResetEmail={sendResetEmail} />}
        loading={() => <span>Sender forespørsel...</span>}
        failure={err => <span>Feil: {err.message}</span>}
        success={() => <span>{RESET_EMAIL_SENT_MESSAGE}</span>}
      />
      <Button title="Tilbake" type="button" onClick={() => history.goBack()} />
    </FormPage>
  );
};

PasswordResetPage.propTypes = {
  sendResetEmail: PropTypes.func.isRequired,
  passwordResetState: PropTypes.object.isRequired,
  resetState: PropTypes.func.isRequired,
};

const mapStateToProps = ({ passwordReset }) => ({
  passwordResetState: passwordReset.passwordResetState,
});

const mapDispatchToProps = dispatch => ({
  sendResetEmail: a.sendPasswordResetEmail(dispatch),
  resetState: a.resetState(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordResetPage);
