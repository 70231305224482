import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Page, H1, H3
} from 'rf-ui';
import * as paths from 'rf-lib/routePaths';
import * as a from '../../store/actions';

const QuitPage = ({ authUser, ...props }) => {
  useEffect(
    () => { props.signOut(); },
    []
  );

  return (
    <Page>
      <H1>Lykkehjulet</H1>
      <H3>Velkommen tilbake!</H3>
      { !authUser && <Redirect push to={paths.HOME} />}
    </Page>
  );
};

QuitPage.propTypes = {
  authUser: PropTypes.object,
  signOut: PropTypes.func.isRequired,
};

const mapStateToProps = ({ session }) => ({
  authUser: session.authUser,
});

const mapDispatchToProps = dispatch => ({
  signOut: player => a.signOutUser(dispatch, player),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuitPage);
