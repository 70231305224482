import { toAction } from 'rf-lib';
import * as urls from 'rf-lib/urls';
import * as t from './constants';
import { games as gamesAPI, groups as groupsAPI } from '../../api';

const handleFetchErrors = (response, dispatch) => {
  if (!response.ok) {
    dispatch(toAction(t.START_GAME_FAILURE, { message: 'Error in external fetch' }));
  }
  return response;
};

export const startGame = dispatch =>
  gameId => {
    dispatch(toAction(t.START_GAME));

    return fetch(`${urls.pingBackend}`)
      .then(handleFetchErrors)
      .then(pingRes =>
        fetch(`${urls.startGame}${gameId}`)
          .then(handleFetchErrors)
          .then(startRes => dispatch(toAction(t.START_GAME_SUCCESS, startRes))))
      .catch(err => dispatch(toAction(t.START_GAME_FAILURE, err)));
  };

export const setCurrentAnimation = dispatch =>
  roundNumber => {
    dispatch(toAction(t.SET_CURRENT_ANIMATION, roundNumber));
  };

export const setIsSpinning = dispatch =>
  roundNumber => {
    dispatch(toAction(t.SET_IS_SPINNING, true));
  };

export const fetchGame = dispatch =>
  gameId => {
    dispatch(toAction(t.FETCH_GAME));

    return gamesAPI.fetchGame(gameId)
      .then(gameData => dispatch(toAction(t.FETCH_GAME_SUCCESS, gameData)))
      .catch(err => dispatch(toAction(t.FETCH_GAME_FAILURE, err)));
  };

export const updateGame = dispatch =>
  (gameId, game) => {
    dispatch(toAction(t.UPDATE_GAME));

    return gamesAPI.updateGame(gameId, game)
      .then(gameData => dispatch(toAction(t.UPDATE_GAME_SUCCESS, gameData)))
      .catch(err => dispatch(toAction(t.UPDATE_GAME_FAILURE, err)));
  };

export const resetUpdateState = dispatch =>
  () => { dispatch(toAction(t.RESET_UPDATE_GAME_STATE)); };

export const fetchGames = dispatch =>
  playerId => {
    dispatch(toAction(t.FETCH_GAMES));

    return gamesAPI.fetchGames(playerId)
      .then(gamesData => dispatch(toAction(t.FETCH_GAMES_SUCCESS, gamesData)))
      .catch(err => dispatch(toAction(t.FETCH_GAMES_FAILURE, err)));
  };

export const resetCurrentGame = dispatch =>
  () => { dispatch(toAction(t.RESET_CURRENT_GAME)); };

export const addGameListener = dispatch =>
  gameId => {
    dispatch(toAction(t.ADD_GAME_LISTENER));

    return gamesAPI.onGameChange(
      gameId,
      game =>
        dispatch(toAction(t.GAME_LISTENER_SUCCESS, game.val())),
      err =>
        dispatch(toAction(t.GAME_LISTENER_FAILURE, err)),
    );
  };

export const removeGameListener = dispatch =>
  gameId => {
    gamesAPI.removeGameListener(gameId);
    dispatch(toAction(t.REMOVE_GAME_LISTENER));
  };

export const addGameRunnerListener = dispatch =>
  gameId => {
    dispatch(toAction(t.ADD_GAMERUNNER_LISTENER));

    return gamesAPI.onGameRunnerChange(
      gameId,
      gameRunner =>
        dispatch(toAction(t.GAMERUNNER_LISTENER_SUCCESS, gameRunner.val())),
      err =>
        dispatch(toAction(t.GAMERUNNER_LISTENER_FAILURE, err)),
    );
  };

export const removeGameRunnerListener = dispatch =>
  gameId => {
    gamesAPI.removeGameRunnerListener(gameId);
    dispatch(toAction(t.REMOVE_GAMERUNNER_LISTENER));
  };

export const addTicketsListener = dispatch =>
  gameId => {
    dispatch(toAction(t.ADD_TICKETS_LISTENER));

    return gamesAPI.onTicketsChange(
      gameId,
      Tickets =>
        dispatch(toAction(t.TICKETS_LISTENER_SUCCESS, Tickets.val())),
      err =>
        dispatch(toAction(t.TICKETS_LISTENER_FAILURE, err)),
    );
  };

export const removeTicketsListener = dispatch =>
  gameId => {
    gamesAPI.removeTicketsListener(gameId);
    dispatch(toAction(t.REMOVE_TICKETS_LISTENER));
  };

export const handleBuy = dispatch =>
  (gameId, playerId) => {
    gamesAPI.buyTicket(gameId, playerId);
    dispatch(toAction(t.BUY_TICKET));
  };

export const handleSell = dispatch =>
  (gameId, playerId) => {
    gamesAPI.sellTicket(gameId, playerId);
    dispatch(toAction(t.SELL_TICKET));
  };

export const fillTestData = dispatch =>
  gameId => {
    dispatch(toAction(t.FILL_TEST_DATA));

    return gamesAPI.fillTestData(gameId)
      .then(() => dispatch(toAction(t.FILL_TEST_DATA_SUCCESS, { result: true })))
      .catch(err => dispatch(toAction(t.FILL_TEST_DATA_FAILURE, err)));
  };

export const resetFillTestDataRemoteData = dispatch =>
  () => {
    dispatch(toAction(t.RESET_FILL_TEST_DATA_REMOTEDATA));
  };

export const resetGame = dispatch =>
  gameId => {
    dispatch(toAction(t.RESET_GAME));

    return gamesAPI.resetGame(gameId)
      .then(() => dispatch(toAction(t.RESET_GAME_SUCCESS, { result: true })))
      .catch(err => dispatch(toAction(t.RESET_GAME_FAILURE, err)));
  };

export const resetResetGameRemoteData = dispatch =>
  () => {
    dispatch(toAction(t.RESET_RESET_GAME_REMOTEDATA));
  };

export const fetchGroupDetails = dispatch =>
  groupID => {
    dispatch(toAction(t.FETCH_GROUP_DETAILS));

    return groupsAPI.fetchGroupDetails(groupID)
      .then(group => dispatch(toAction(t.FETCH_GROUP_DETAILS_SUCCESS, group)))
      .catch(err => dispatch(toAction(t.FETCH_GROUP_DETAILS_FAILURE, err)));
  };
