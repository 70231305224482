import styled from 'styled-components';
import { get } from 'rf-lib';

export const H1 = styled.h1`
  @media only screen and (min-width: 600px) {
    font-size: ${get('fontHeadingLg')};
  };
  @media only screen and (max-width:600px) {
    font-size: ${get('fontHeadingSm')}
  }
  padding: ${get('spacingLg')} 0;
`;

export const H2 = styled.h2`
  font-size: ${get('fontHeadingMd')};
  padding: ${get('spacingMd')} 0;
`;

export const H3 = styled.h3`
  font-size: ${get('fontHeadingSm')};
  padding: ${get('spacingSm')} 0;
`;
