import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { get } from 'rf-lib';
import { FormField, DateField } from 'rf-ui';

const Container = styled.div`
  display: flex;
  flex-direction: inherit;
  flex-wrap: wrap;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 10px;
  grid-gap: 20px
  margin: ${get('spacingSm')} 0 ${get('spacingMd')} 0;
`;

const GameFormDetails = ({
  items, onChange, ...props
}) => (
  <Container>
    <FormField
      name="maxTickets"
      type="number"
      min="1"
      title="Maks. lodd"
/>
    <FormField
      name="maxTicketsPerPlayer"
      type="number"
      min="1"
      title="Maks. lodd per spiller"
/>
    <FormField
      name="numberOfPrizes"
      type="number"
      min="1"
      title="Antall premier"
/>
    <FormField
      name="ticketPrice"
      type="number"
      min="0"
      title="Pris per lodd"
/>
    <DateField
      name="startTime"
      title="Velg starttid"
      class=""
/>
  </Container>
);

GameFormDetails.propTypes = {
  items: PropTypes.array,
  onChange: PropTypes.func,
};

export default GameFormDetails;
