import { toAction } from 'rf-lib';
import { groups } from '../../../api';
import * as t from './constants';

export const getGroupDetails = dispatch =>
  groupId => {
    dispatch(toAction(t.FETCH_GROUP_DETAILS));

    return groups.fetchGroupDetails(groupId)
      .then(groupsData => dispatch(toAction(t.FETCH_GROUP_DETAILS_SUCCESS, groupsData)))
      .catch(err => dispatch(toAction(t.FETCH_GROUP_DETAILS_FAILURE, err)));
  };

export const updateGroup = dispatch =>
  (groupId, updates, groupImage) => {
    dispatch(toAction(t.UPDATE_GROUP));

    return groups.updateGroup(groupId, updates, groupImage)
      .then(() => dispatch(toAction(t.UPDATE_GROUP_SUCCESS)))
      .catch(err => dispatch(toAction(t.UPDATE_GROUP_FAILURE, err)));
  };

export const deleteGroup = dispatch =>
  groupId => {
    dispatch(toAction(t.DELETE_GROUP));

    return groups.deleteGroup(groupId)
      .then(() => dispatch(toAction(t.DELETE_GROUP_SUCCESS)))
      .catch(err => dispatch(toAction(t.DELETE_GROUP_FAILURE, err)));
  };

export const deleteGroupImage = dispatch =>
  groupId => {
    dispatch(toAction(t.DELETE_GROUP));

    return groups.removeImage(groupId)
      .then(() => dispatch(toAction(t.DELETE_GROUP_SUCCESS)))
      .catch(err => dispatch(toAction(t.DELETE_GROUP_FAILURE, err)));
  };
