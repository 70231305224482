import { toAction } from 'rf-lib';
import * as t from './constants';
import { authUser } from '../../api';

const createUser = dispatch =>
  (email, password, displayName) => {
    dispatch(toAction(t.CREATE_USER));

    return authUser.create(email, password, displayName)
      .then(player => dispatch(toAction(t.CREATE_USER_SUCCESS, player)))
      .catch(err => dispatch(toAction(t.CREATE_USER_FAILURE, err)));
  };

export default createUser;
