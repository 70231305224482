export const FETCH_GROUP_DETAILS = 'playerGroups/FETCH_GROUP_DETAILS';
export const FETCH_GROUP_DETAILS_FAILURE = 'playerGroups/FETCH_GROUP_DETAILS_FAILURE';
export const FETCH_GROUP_DETAILS_SUCCESS = 'playerGroups/FETCH_GROUP_DETAILS_SUCCESS';

export const FETCH_GROUP_PLAYERS = 'playerGroups/FETCH_GROUP_PLAYERS';
export const FETCH_GROUP_PLAYERS_FAILURE = 'playerGroups/FETCH_GROUP_PLAYERS_FAILURE';
export const FETCH_GROUP_PLAYERS_SUCCESS = 'playerGroups/FETCH_GROUP_PLAYERS_SUCCESS';

export const DELETE_MEMBER = 'playerGroups/DELETE_MEMBER';
export const DELETE_MEMBER_FAILURE = 'playerGroups/DELETE_MEMBER_FAILURE';
export const DELETE_MEMBER_SUCCESS = 'playerGroups/DELETE_MEMBER_SUCCESS';

export const IS_PLAYER_MEMBER = 'playerGroups/IS_PLAYER_MEMBER';
export const IS_PLAYER_MEMBER_SUCCESS = 'playerGroups/IS_PLAYER_MEMBER_SUCCESS';
export const IS_PLAYER_MEMBER_FAILURE = 'playerGroups/IS_PLAYER_MEMBER_FAILURE';

export const DELETE_GAME = 'playerGroups/DELETE_GAME';
export const DELETE_GAME_FAILURE = 'playerGroups/DELETE_GAME_FAILURE';
export const DELETE_GAME_SUCCESS = 'playerGroups/DELETE_GAME_SUCCESS';
