import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { get } from 'rf-lib';

const Input = styled.input`
  border-radius: 3px;
  border: none;
  padding: 0.6rem;
  height: auto;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${get('spacingSm')} 0 ${get('spacingMd')} 0;
`;

const Label = styled.label`
  font-size: ${get('fontTextLg')};
  margin-bottom: ${get('spacingXs')};
`;

const TextField = ({
  title, value, onChange, type = 'text', disabled = false, touched, errmsg, ...props
}) => (
  <Container>
    <Label htmlFor={props.id}>{title}</Label>
    <Input
      type={type}
      value={value}
      disabled={disabled}
      {...props}
      onChange={evt => onChange(evt.target.value)} />
  </Container>
);

TextField.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  touched: PropTypes.bool,
  errmsg: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool
};

export default TextField;
