const isAuthorized = (requiredRole, groupId, authUser) => {
  if (!requiredRole) return true;

  if (!authUser || !authUser.roles) return false;

  const group = authUser.roles.filter(gr => gr.groupId === groupId);

  if (!group) return false;
  if (!group[0]) return false;

  if (requiredRole==='player') return true;

  return group[0].roles.filter(r => r.role === requiredRole && r.authorized === true).length > 0;
};

export default isAuthorized;
