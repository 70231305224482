import { toAction } from 'rf-lib';
import { transactions as transactionsAPI, groups as groupsAPI } from '../../api';
import * as t from './constants';

export const fetchPlayerTransactions = dispatch =>
  (groupId, playerId) => {
    dispatch(toAction(t.FETCH_TRANSACTIONS));

    return transactionsAPI.fetchPlayerTransactions(groupId, playerId)
      .then(transactions => dispatch(toAction(t.FETCH_TRANSACTIONS_SUCCESS, transactions)))
      .catch(err => dispatch(toAction(t.FETCH_TRANSACTIONS_FAILURE, err)));
  };

export const addPlayerBalanceListener = dispatch =>
  (groupId, playerId) => {
    dispatch(toAction(t.BALANCE_LISTENER));

    return transactionsAPI.onTransactionsChange(
      groupId,
      playerId,
      snap => dispatch(toAction(t.BALANCE_LISTENER_SUCCESS,
        transactionsAPI.calculateBalance(snap.val()))),
      err =>
        dispatch(toAction(t.BALANCE_LISTENER_FAILURE, err))
    );
  };

export const removePlayerBalanceListener = dispatch =>
  (groupId, playerId) => {
    transactionsAPI.removeTransactionsListener(groupId, playerId);
    dispatch(toAction(t.REMOVE_BALANCE_LISTENER));
  };

export const fetchPlayersInCurrentGroup = dispatch =>
  groupId => {
    dispatch(toAction(t.FETCH_PLAYERS));

    return groupsAPI.fetchGroupPlayers(groupId)
      .then(groups => dispatch(toAction(t.FETCH_PLAYERS_SUCCESS, groups)))
      .catch(err => dispatch(toAction(t.FETCH_PLAYERS_FAILURE, err)));
  };

export const onCreateTransaction = dispatch =>
  (groupId, playerId, amount, type, userId) => {
    dispatch(toAction(t.CREATE_TRANSACTION));

    return transactionsAPI.createTransaction(groupId, playerId, {
      amount,
      date: Date.now(),
      type,
      regBy: userId
    })
      .then(m => dispatch(toAction(t.CREATE_TRANSACTION_SUCCESS, m)))
      .catch(err => dispatch(toAction(t.CREATE_TRANSACTION_FAILURE, err)));
  };

export const resetPlayerTransactionState = dispatch =>
  () => {
    dispatch(toAction(t.RESET_PLAYER_TRANSACTION_STATE));
  };
