import { handleActions } from 'redux-actions';
import { RemoteData } from 'rf-lib';
import * as t from './constants';

const defaultState = {
  passwordResetState: RemoteData.NotAsked
};

const reducer = handleActions({
  [t.PASSWORD_RESET]: (state, action) => ({
    ...state, passwordResetState: RemoteData.Loading
  }),

  [t.PASSWORD_RESET_FAILURE]: (state, action) => ({
    ...state, passwordResetState: RemoteData.Failure(action.payload)
  }),

  [t.PASSWORD_RESET_SUCCESS]: (state, action) => ({
    ...state, passwordResetState: RemoteData.Success(null)
  }),
  [t.RESET_STATE]: state => ({
    ...state, passwordResetState: RemoteData.NotAsked
  })
}, defaultState);

export default reducer;
