import { toAction } from 'rf-lib';
import * as t from './constants';
import { authUser, players } from '../../api';

export const signOutUser = dispatch =>
  authUser.signOut()
    .then(() => {
      dispatch(toAction(t.SIGN_OUT));
    })
    .catch(err => {
      dispatch(toAction(t.SIGN_OUT_FAILURE, err));
    });

export const verifyAuth = () =>
  dispatch => {
    authUser.onAuthStateChanged(user => {
      if (user) {
        // start listener for authorization changes
        authUser.onAuthorizationsChanged(user.uid,
          snap => {
            dispatch(toAction(t.AUTHORIZATIONS_LISTENER_SUCCESS,
              authUser.mapUserRoles(snap.val())));
          },
          err =>
            dispatch(toAction(t.AUTHORIZATIONS_LISTENER_FAILURE, err)));

        localStorage.setItem('authUser', JSON.stringify(user));
        dispatch(toAction(t.SET_AUTH_USER, user));
      } else {
        localStorage.removeItem('authUser');
        dispatch(toAction(t.SIGN_OUT));
      }
    }, () => {
      localStorage.removeItem('authUser');
      dispatch(toAction(t.SIGN_OUT));
    });
  };

export const updatePlayer = (dispatch, player) =>
  new Promise((resolve, reject) =>
    players.set(player)
      .then(storedPlayer =>
        resolve(dispatch(toAction(t.UPDATE_PLAYER_SUCCESS, storedPlayer))))
      .catch(err => {
        dispatch(toAction(t.UPDATE_PLAYER_FAILURE, err));
        return reject(new Error(err));
      }));

export const fetchAllPlayers = dispatch =>
  () => {
    dispatch(toAction(t.FETCH_PLAYERS));

    return players.on(
      updatedPlayers =>
        dispatch(toAction(t.FETCH_PLAYERS_SUCCESS, updatedPlayers)),
      err =>
        dispatch(toAction(t.FETCH_PLAYERS_FAILURE, err)),
    );
  };

export const setRedirectPath = path =>
  dispatch => dispatch(toAction(t.SET_REDIRECT_PATH, path));

