import { useState } from 'react';
import RemoteData from './RemoteData';

function useRemoteService(fn) {
  const [status, setStatus] = useState(RemoteData.NotAsked);

  const callService = (...args) => {
    setStatus(RemoteData.Loading);

    const promiseFn = fn(...args);

    if ((!(promiseFn instanceof Promise))) {
      // eslint-disable-next-line no-console
      console.error('Use remote service expects a promise');
    }

    promiseFn
      .then(data => setStatus(RemoteData.Success(data)))
      .catch(err => setStatus(RemoteData.Failure(err)));
  };
  return [status, callService];
}

export default useRemoteService;
