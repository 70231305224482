import firebase from '../firebase/firebase';

const playerRef = uid => firebase.db.ref(`players/${uid}`);

const gameRef = gameID => firebase.db.ref(`games/${gameID}`);

const groupsRef = () => firebase.db.ref('groups');

const groupRef = id => firebase.db.ref(`groups/${id}`);

const groupImageRef = id => firebase.storage.ref(`groupImages/${id}`);

const playerGroupsRef = playerID => firebase.db.ref(`playerGroups/${playerID}`);

const groupPlayersRef = groupID => firebase.db.ref(`groupPlayers/${groupID}`);

const groupGamesRef = groupID => firebase.db.ref(`groupGames/${groupID}`);

const playerGroupRef = (playerID, groupID) => firebase.db.ref(`playerGroups/${playerID}/${groupID}`);

const groupPlayerRef = (groupID, playerID) => firebase.db.ref(`groupPlayers/${groupID}/${playerID}`);

export const fetchGroup = groupID => groupRef(groupID).once('value');

const mapGroups = groupSnapshots =>
  groupSnapshots.map(groupSnapshot => ({
    id: groupSnapshot.key,
    ...groupSnapshot.val()
  }));

const mapGames = gameSnapshots =>
  gameSnapshots.map(gameSnapshot => ({
    id: gameSnapshot.key,
    ...gameSnapshot.val()
  }));

export const fetchGroupPlayers = groupId => {
  const allPromises = groupPlayersRef(groupId)
    .once('value')
    .then(snapshot => {
      const promises = [];
      snapshot.forEach(groupPlayerSnapshot => {
        const playerPromise = playerRef(groupPlayerSnapshot.key).once('value')
          .then(playerSnapshot => ({
            ...playerSnapshot.val(),
            id: playerSnapshot.key,
            roles: groupPlayerSnapshot.val(),
          }));
        promises.push(playerPromise);
      });
      return Promise.all(promises);
    });
  return allPromises;
};

export const fetchPlayerGroups = playerId => {
  const allPromises = playerGroupsRef(playerId)
    .once('value')
    .then(snapshot => {
      const promises = [];
      snapshot.forEach(childSnapshot => {
        const groupPromise = groupRef(childSnapshot.key).once('value');
        promises.push(groupPromise);
      });
      return Promise.all(promises);
    });
  return allPromises.then(mapGroups);
};

const fetchGroupGames = groupID => {
  const allPromises = groupGamesRef(groupID)
    .once('value')
    .then(snapshot => {
      const promises = [];
      snapshot.forEach(childSnapshot => {
        const gamePromise = gameRef(childSnapshot.key).once('value');
        promises.push(gamePromise);
      });
      return Promise.all(promises);
    });
  return allPromises.then(mapGames);
};

const groupExists = groupID =>
  groupRef(groupID).once('value')
    .then(snapshot => snapshot.exists());

export const fetchGroupDetails = groupId =>
  Promise.all([fetchGroupPlayers(groupId), fetchGroupGames(groupId)])
    .then(([players, games]) =>
      fetchGroup(groupId).then(groupSnapshot => ({
        ...groupSnapshot.val(),
        id: groupId,
        players,
        games
      })));

const defaultPlayerRoles = {
  admin: false,
  accountant: false,
  gameMaster: false
};

const defaultAdminRoles = {
  admin: true,
  accountant: true,
  gameMaster: true
};

const addPlayerToGroup = (groupID, playerID, isCreator) => {
  const roles = isCreator ? defaultAdminRoles: defaultPlayerRoles;

  groupPlayersRef(groupID)
    .once('value')
    .then(snapshot => ({
      ...snapshot.val(),
      [playerID]: roles
    }))
    .then(updatedFields => groupPlayersRef(groupID).update(updatedFields));
  return Promise.resolve();
};

const addGroupToPlayer = (groupID, playerID, isCreator) => {
  const roles = isCreator ? defaultAdminRoles: defaultPlayerRoles;

  playerGroupsRef(playerID)
    .once('value')
    .then(snapshot => ({
      ...snapshot.val(),
      [groupID]: roles
    }))
    .then(updatedFields => playerGroupsRef(playerID).update(updatedFields));
};

export const addAdmin = (groupId, playerId) => (
  groupExists(groupId)
    ? Promise.all([
      addPlayerToGroup(groupId, playerId, true),
      addGroupToPlayer(groupId, playerId, true),
    ])
    : Promise.reject(new Error(`Group does not exist ${groupId}`))
);

export const addMember = groupID => {
  const playerID = firebase.playerID();

  return groupExists(groupID)
    ? Promise.all([
      addPlayerToGroup(groupID, playerID, false),
      addGroupToPlayer(groupID, playerID, false),
    ])
    : Promise.reject(new Error(`Group does not exist ${groupID}`));
};

export const saveAuthorizations = (groupId, playerId, roles) => (
  Promise.all([
    playerGroupRef(playerId, groupId).set(roles),
    groupPlayerRef(groupId, playerId).set(roles),
  ])
);

export const removeMember = (groupId, playerId) =>
  Promise.all([
    playerGroupRef(playerId, groupId).remove(),
    groupPlayerRef(groupId, playerId).remove(),
  ]);

export const setImage = (groupId, groupImage) =>
  groupImageRef(groupId)
    .put(groupImage)
    .then(snapshot => snapshot.ref.getDownloadURL());

export const deleteImage = groupId =>
  groupRef(`${groupId}/imageUrl`).once('value', snapshot => {
    if (snapshot.val()) {
      return groupImageRef(groupId).delete();
    }
    return Promise.resolve();
  });

const setImageIfPresent = (groupID, groupImage) => {
  if (groupImage) {
    return setImage(groupID, groupImage)
      .then(url => {
        groupRef(groupID)
          .once('value')
          .then(snapshot =>
            groupRef(groupID).set({
              ...snapshot.val(),
              imageUrl: url
            }));
        return groupID;
      });
  }
  return groupID;
};

export const createGroup = (playerId, groupName, groupDescription, groupImage) => (
  groupsRef()
    .push({
      groupName,
      groupDescription
    })
    .then(snap => groupRef(snap.key)
      .once('value')
      .then(group => {
        addAdmin(group.key, playerId)
          .then(() => setImageIfPresent(group.key, groupImage));
        return ({
          ...group.val(),
          id: group.key
        });
      }))
);

export const updateGroup = (groupId, updates, groupImage) =>
  groupRef(groupId)
    .once('value')
    .then(snapshot => {
      const updatedGroup = {
        ...snapshot.val(),
        ...updates
      };
      if (groupImage) {
        return setImage(groupId, groupImage)
          .then(imageUrl => ({
            ...updatedGroup,
            imageUrl
          }))
          .then(updatedFields => groupRef(groupId).update(updatedFields));
      }
      return groupRef(groupId).update(updatedGroup);
    });

export const removeImage = groupId =>
  groupRef(groupId)
    .once('value')
    .then(snapshot => {
      const updatedGroup = {
        ...snapshot.val(),
      };
      deleteImage(groupId)
        .then(() => ({
          ...updatedGroup,
          imageUrl: ''
        }))
        .then(updatedFields => groupRef(groupId).update(updatedFields));
    });

export const deleteGroup = groupID => {
  // Fetch group members
  groupPlayersRef(groupID)
    .once('value')
    .then(snapshot => {
      snapshot.forEach(childSnapshot => {
        // Remove the group for each member
        firebase.db
          .ref(`playerGroups/${childSnapshot.key}/${groupID}`)
          .remove();
      });
    })
    .then(() => groupPlayersRef(groupID).remove());
  // Remove the members from the group
  // Delete group entry and group image
  deleteImage(groupID);
  return groupRef(groupID).remove();
};

export const isPlayerMember = groupID =>
  groupPlayersRef(groupID)
    .once('value')
    .then(snapshot => {
      let memberExist = false;
      snapshot.forEach(childSnapshot => {
        if (childSnapshot.key === firebase.playerID()) {
          memberExist = true;
        }
      });
      return memberExist;
    });
