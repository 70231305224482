const fileToB64 = file =>
  new Promise((resolve, reject) => {
    if (!file) {
      reject(new Error('No file found'));
    }

    const reader = new window.FileReader();

    reader.addEventListener('load', () => {
      resolve(reader.result);
    }, false);

    reader.readAsDataURL(file);
  });

export default fileToB64;
