import firebase from '../firebase/firebase';
import hashmapToArray from './mappers';

const playerRef = uid => firebase.db.ref(`players/${uid}`);

const playersRef = () => firebase.db.ref('players');

const playerImagesRoot = () => firebase.storage.ref().child('players');

const imageReference = uid => playerImagesRoot().child(`${uid}/avatar`);

const playerGroupsRef = playerID => firebase.db.ref(`playerGroups/${playerID}`);

export const get = uid => playerRef(uid).once('value');

export const create = player =>
  playersRef().push(player);

export const update = (id, updates) => playerRef(id).update(updates);

const setImage = (uid, file) =>
  imageReference(uid)
    .put(file)
    .then(snapshot => snapshot.ref.getDownloadURL());

export const set = playerCandidate => {
  const { uid: key, image, ...rest } = playerCandidate;

  if (image) {
    return setImage(key, image)
      .then(url => {
        playerRef(key).set({ ...rest, imageUrl: url });
        return url;
      })
      .then(url => ({ ...playerCandidate, imageUrl: url }));
  }

  return playerRef(key).update(rest).then(() => playerCandidate);
};

export const remove = playerID =>
  playerGroupsRef(playerID).once('value')
    .then(snapshot => {
      snapshot.forEach(childSnapshot => {
        // Remove the player as member and admin from groups
        firebase.db.ref(`groupPlayers/${childSnapshot.key}/${playerID}`).remove();
      });
    })
    .then(() => playerGroupsRef(playerID).remove())
    .then(() => playerRef(playerID).remove());

const mapToArray = snapshot => {
  const hashmap = snapshot.val();

  return Object.keys(hashmap || {}).map(
    key => ({
      ...hashmap[key],
      uid: key
    })
  );
};

export const fetchPlayers = () =>
  playersRef()
    .once('value')
    .then(snapshot => mapToArray(snapshot));

export const on = (successCallback, errorCallback) =>
  playersRef().on('value', hashmapToArray(successCallback), errorCallback);

export const off = () =>
  playersRef().off();
