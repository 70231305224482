import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { get } from 'rf-lib';
import Menu from './Menu';

const Page = styled.div`
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: ${get('spacingMd')};
  margin-top: ${get('spacingLg')};
`;

const Children = styled.div`
  width: ${get('widthContent')};
`;

const PageWrapper = ({ children }) => (
  <Page>
    <Menu />
    <Content>
      <Children>
        {children}
      </Children>
    </Content>
  </Page>
);

PageWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired
};

export default PageWrapper;
