import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  Text,
  Button
} from 'rf-ui';

const MessageOuterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MessageInnerContainer = styled.div`
  display: flex;
  max-width: 400;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  align-items: space-between;
`;

const NotAuthorized = ({ message }) => {
  const history = useHistory();

  return (
    <MessageOuterContainer>
      <MessageInnerContainer>
        <Text>{message}</Text>
        <Button title="Tilbake" type="button" onClick={() => history.goBack()} />
      </MessageInnerContainer>
    </MessageOuterContainer>
  );
};

NotAuthorized.propTypes = {
  message: PropTypes.string,
};

export default NotAuthorized;
