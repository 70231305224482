import { handleActions } from 'redux-actions';
import { RemoteData } from 'rf-lib';
import * as t from './constants';

const defaultState = {
  newPlayerGroupState: RemoteData.NotAsked
};

const reducer = handleActions({
  [t.CREATE_GROUP]: (state, action) => ({
    ...state, newPlayerGroupState: RemoteData.Loading
  }),

  [t.CREATE_GROUP_SUCCESS]: (state, action) => ({
    ...state, newPlayerGroupState: RemoteData.Success(action.payload)
  }),

  [t.CREATE_GROUP_FAILURE]: (state, action) => ({
    ...state, newPlayerGroupState: RemoteData.Failure(action.payload)
  }),

  [t.RESET_STATE]: state => ({
    ...state, newPlayerGroupState: RemoteData.NotAsked
  })
}, defaultState);

export default reducer;

