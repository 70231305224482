import { handleActions } from 'redux-actions';
import { RemoteData } from 'rf-lib';
import * as t from './constants';

const defaultState = {
  messageState: RemoteData.NotAsked,
  editMessageState: RemoteData.NotAsked,
  createmessageState: RemoteData.NotAsked,
};

const reducer = handleActions({
  [t.FETCH_MESSAGES]: (state, action) => ({
    ...state,
    messageState: RemoteData.Loading,
  }),

  [t.FETCH_MESSAGES_FAILURE]: (state, action) => ({
    ...state,
    messageState: RemoteData.Failure(action.payload),
  }),

  [t.FETCH_MESSAGES_SUCCESS]: (state, action) => ({
    ...state,
    messages: action.payload,
    messageState: RemoteData.Success(action.payload),
  }),

  [t.CREATE_MESSAGE]: (state, action) => ({
    ...state,
    createmessageState: RemoteData.Loading,
  }),

  [t.CREATE_MESSAGE_SUCCESS]: (state, action) => ({
    ...state,
    createmessageState: RemoteData.Success(action.payload),
  }),

  [t.CREATE_MESSAGE_FAILURE]: (state, action) => ({
    ...state,
    createmessageState: RemoteData.Failure(action.payload),
  }),

  [t.REMOVE_MESSAGE]: (state, action) => ({
    ...state,
    editMessageState: RemoteData.Loading,
  }),

  [t.REMOVE_MESSAGE_SUCCESS]: (state, action) => ({
    ...state,
    editMessageState: RemoteData.Success(action.payload),
  }),

  [t.REMOVE_MESSAGE_FAILURE]: (state, action) => ({
    ...state,
    editMessageState: RemoteData.Failure(action.payload),
  }),

  [t.EDIT_MESSAGE]: (state, action) => ({
    ...state,
    editMessageState: RemoteData.Loading,
  }),

  [t.EDIT_MESSAGE_SUCCESS]: (state, action) => ({
    ...state,
    editMessageState: RemoteData.Success(action.payload),
  }),

  [t.EDIT_MESSAGE_FAILURE]: (state, action) => ({
    ...state,
    editMessageState: RemoteData.Failure(action.payload),
  }),

}, defaultState);

export default reducer;
