import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { get } from 'rf-lib';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${get('spacingSm')} 0 ${get('spacingMd')} 0;
`;

const Select = styled.select`
  display: block;
  font-size: 16px;
  border-radius: 3px;
  padding: 0.6rem;
  height: auto;
`;

const Dropdown = ({ items, onChange, ...props }) => (
  <Container>
    <Select onChange={onChange} {...props}>
      <option>Vennligst velg spillergruppe</option>
      {items.map((item, key) => (
        <option key={key} value={item.id}>
          {item.title}
        </option>
      ))}
    </Select>
  </Container>
);

Dropdown.propTypes = {
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

export default Dropdown;
