import styled from 'styled-components';
import { get } from 'rf-lib';

export const Text = styled.p``;

export const TextSmall = styled.p`
  font-size: ${get('fontTextSm')};
  padding: ${get('spacingXs')} 0;
`;

export const TextLarge = styled.p`
  font-size: ${get('fontTextLg')};
  padding: ${get('spacingXs')} 0;
`;
