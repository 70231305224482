/* eslint-disable no-param-reassign */
import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firebase-storage';

const prodConfig = {
  apiKey: 'AIzaSyCUzjXzOtXchhbFgISc8pveTWGpGHCBoZM',
  authDomain: 'rf-lykkehjulet.firebaseapp.com',
  databaseURL: 'https://rf-lykkehjulet.firebaseio.com',
  projectId: 'rf-lykkehjulet',
  storageBucket: 'rf-lykkehjulet.appspot.com',
  messagingSenderId: '518073179777',
  appId: '1:518073179777:web:8c8ea6150b122ab77a7780',
  measurementId: 'G-JVNNHPK4N7'
};

// const devConfig = {
//   apiKey: 'AIzaSyAsNqn2DngmGio5uZyxSXUIZwQ5ijfT4uQ',
//   authDomain: 'rf-lykkehjulet-test.firebaseapp.com',
//   databaseURL: 'https://rf-lykkehjulet-test.firebaseio.com',
//   projectId: 'rf-lykkehjulet-test',
//   storageBucket: '"rf-lykkehjulet-test.appspot.com',
//   messagingSenderId: '"925755457850',
//   appId: '1:925755457850:web:daf2701ab750a668d01003',
//   measurementId: 'G-2VQ2SZFZYR'
// };
//
// const config = process.env.NODE_ENV ==='production' ? prodConfig : devConfig;

// const isDevelopment = process.env.NODE_ENV === 'development';

// Hack until we get the NODE_ENV set:
const config = prodConfig;

class Firebase {
  constructor() {
    app.initializeApp(config);

    /* Helper */
    this.serverValue = app.database.ServerValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */
    this.auth = app.auth();
    this.db = app.database();
    this.storage = app.storage();
  }

  // *** Player/groups API ***
  playerID = () => this.auth.currentUser.uid;

  playerGroups = uid => this.db.ref(`players/${uid}/playerGroups`);

  playerGroup = (uid, groupID) => this.db.ref(`players/${uid}/playerGroups/${groupID}`);

  getGroupImageURL = id => this.groupImage(id).getDownloadURL();
}

export default new Firebase();
