import React from 'react';
import PropTypes from 'prop-types';
import MessageItem from './MessageItem';

const MessageList = ({
  authUser,
  messages,
  onEditMessage,
  onRemoveMessage,
  gameId,
}) => (
  <div>
    {messages.map(message => (
      <MessageItem
        key={message.uid}
        authUser={authUser}
        message={message}
        onEditMessage={onEditMessage}
        onRemoveMessage={onRemoveMessage}
        gameId={gameId}
      />
    ))}
  </div>
);

MessageList.propTypes = {
  authUser: PropTypes.object.isRequired,
  messages: PropTypes.array,
  onEditMessage: PropTypes.func,
  onRemoveMessage: PropTypes.func,
  gameId: PropTypes.string,
};

export default MessageList;
