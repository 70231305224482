import { handleActions } from 'redux-actions';
import { RemoteData } from 'rf-lib';
import * as t from './constants';

const defaultState = {
  transactionState: RemoteData.NotAsked,
  balanceState: RemoteData.NotAsked,
  createTransactionState: RemoteData.NotAsked,
  playersState: RemoteData.NotAsked
};

const reducer = handleActions({
  [t.FETCH_TRANSACTIONS]: (state, action) => ({
    ...state, transactionState: RemoteData.Loading
  }),
  [t.FETCH_TRANSACTIONS_FAILURE]: (state, action) => ({
    ...state, transactionState: RemoteData.Failure(action.payload)
  }),

  [t.FETCH_TRANSACTIONS_SUCCESS]: (state, action) => ({
    ...state, transactionState: RemoteData.Success(action.payload)
  }),

  [t.CREATE_TRANSACTION]: (state, action) => ({
    ...state, createTransactionState: RemoteData.Loading
  }),
  [t.CREATE_TRANSACTION_FAILURE]: (state, action) => ({
    ...state, createTransactionState: RemoteData.Failure(action.payload)
  }),

  [t.CREATE_TRANSACTION_SUCCESS]: (state, action) => ({
    ...state, createTransactionState: RemoteData.Success(action.payload)
  }),

  [t.BALANCE_LISTENER]: (state, action) => ({
    ...state, balanceState: RemoteData.Loading
  }),
  [t.BALANCE_LISTENER_FAILURE]: (state, action) => ({
    ...state, balanceState: RemoteData.Failure(action.payload)
  }),

  [t.BALANCE_LISTENER_SUCCESS]: (state, action) => ({
    ...state, balanceState: RemoteData.Success(action.payload)
  }),

  [t.FETCH_PLAYERS]: (state, action) => ({
    ...state, playersState: RemoteData.Loading
  }),
  [t.FETCH_PLAYERS_FAILURE]: (state, action) => ({
    ...state, playersState: RemoteData.Failure(action.payload)
  }),
  [t.FETCH_PLAYERS_SUCCESS]: (state, action) => ({
    ...state, playersState: RemoteData.Success(action.payload)
  }),

  [t.RESET_PLAYER_TRANSACTION_STATE]: state => ({
    ...state, transactionState: RemoteData.NotAsked
  }),

}, defaultState);

export default reducer;
