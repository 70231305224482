import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './configureStore';
import App from './App';
import Firebase, { FirebaseContext } from './firebase';
import session from './store/reducers/session';
import * as pageReducers from './pages/reducers';

const allReducers = { session, ...pageReducers };
const store = configureStore(allReducers);

ReactDOM.render(
  <FirebaseContext.Provider value={Firebase}>
    <Provider store={store}>
      <App />
    </Provider>
  </FirebaseContext.Provider>,
  document.getElementById('root')
);
