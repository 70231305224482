import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Text, TextSmall } from 'rf-ui';
import { get } from 'rf-lib';
import defaultProfilePicture from './../../assets/images/default.png';

const gameInfoStyles = {
  stateWrapper: {
    margin: '10px 0px',
    display: 'flex',
    flexAlign: 'center',
    fontSize: '20px'
  },
  gameState: {
    backgroundColor: '#d9534f',
    borderRadius: '2px',
    margin: '0px 4px',
    padding: '5px',
    color: 'white',
    fontSize: '14px',
    display: 'inline-flex',
    alignItems: 'center',
    textTransform: 'uppercase'
  },
  winnerRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '5px'
  },
  winnerRowInnerMargin: {
    marginLeft: '10px'
  }
};

const PlayerProfileImage = styled.img`
  width: 64px;
  height: 64px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  `;

const GameInfoStateContainer = styled.div`
  max-height: 600px;
  min-width: 250px;
  overflow-y: auto;
  border: 1px solid rgba(255, 255, 255, 0.5);
  `;

const PlayerWrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  `;

const PlayerName = styled.span`
  margin-left: 10px;
  `;

const ColouredBackground = styled.div`
  background: ${get('colorBackgroundSecondary')};
  margin-left: 0.2rem;
`;

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: space-between;
  margin: 3;
  padding: 3;
  background: ${get('colorBackgroundSecondary')};
`;

const bgActive = {
  background: 'linear-gradient(to right, transparent 50%, rgba(255, 0, 0, 0.5) 50%)',
  backgroundSize: '200% 100%',
  backgroundPosition: 'left bottom',
  transition: 'all 0.5s'
};

const bgLost = {
  ...bgActive,
  backgroundPosition: 'right bottom'
};

const Tickets = ({ tickets }) =>
  (
    <ColouredBackground>
      <TextSmall>
        {` [${tickets.current}/${tickets.start}]`}
      </TextSmall>
    </ColouredBackground>
  );

Tickets.propTypes = {
  tickets: PropTypes.object.isRequired
};

const player = (id, name, tickets, imageUrl) => (
  {
    id, name, tickets: { start: tickets, current: tickets }, imageUrl
  }
);

const getPlayer = (uid, allPlayers) => allPlayers.find(x => x.uid === uid);

const mapPlayers = (game, players) => {
  let gamePlayers = [];
  if (game.tickets) {
    gamePlayers = Object.keys(game.tickets || {})
      .filter(key => {
        if (game.tickets[key] === 0) {
          return false;
        }
        return true;
      })
      .map(
        key => {
          const { displayName = '<Unknown>', imageUrl = '', } = getPlayer(key, players) || {};
          return player(
            key, displayName, game.tickets[key],
            imageUrl.length > 0 ? imageUrl : defaultProfilePicture
          );
        }
      )
      .reverse();
  }
  return gamePlayers;
};

const mapWinners = (winnerList, players) =>
  winnerList
    .map(({ playerId }) => {
      const { displayName = '<Unknown>', imageUrl = '', } = getPlayer(playerId, players) || {};
      return player(
        playerId, displayName, null,
        imageUrl.length > 0 ? imageUrl : defaultProfilePicture
      );
    });

const mapTicketCount = (players, ticketsLost) => (
  players
    .map(p => {
      const lastTicketIndex = ticketsLost.lastIndexOf(p.id) + 1;
      const ticketsLostCount = ticketsLost
        .filter(t => t === p.id)
        .length;

      const tickets = {
        ...p.tickets,
        current: p.tickets.start - ticketsLostCount
      };

      return ({ ...p, lastTicketIndex, tickets });
    })
);

const compare = (a, b) => {
  if (a > b) return 1;
  if (a < b) return -1;
  return 0;
};

const GameInfoState = ({ gameRunner, game, allPlayers }) => {
  const gameStateLowerCase = gameRunner.gameState ? gameRunner.gameState.toLowerCase() : '';

  const ticketsLost = gameRunner.ticketsLost
    ? gameRunner.ticketsLost.slice(0, gameRunner.lastShownAnimation)
    : [];

  let numberOfTickets = 0;
  let ticketsLeft = 0;
  if (game.tickets) {
    numberOfTickets = Object.values(game.tickets)
      .reduce(
        (accumulator, currentValue) => accumulator + currentValue, 0
      );
  }

  if (numberOfTickets) {
    ticketsLeft = numberOfTickets - ticketsLost.length;
  }

  const gamePlayers = mapTicketCount(mapPlayers(game, allPlayers), ticketsLost)
    .sort((a, b) => compare(b.lastTicketIndex, a.lastTicketIndex));

  // winner tab
  if (gameStateLowerCase === 'ended'
    && gameRunner.lastShownAnimation === gameRunner.ticketsLost.length
    && gameRunner.ticketsLeft === gameRunner.numberOfPrizes) {
    const winnerList = gameRunner.winnerList ? gameRunner.winnerList : [];
    const mappedWinners = mapWinners(winnerList, allPlayers);
    return (
      <GameInfoStateContainer>
        {mappedWinners.map((winner, i) => (
          <div style={gameInfoStyles.winnerRow} key={i}>
            <PlayerProfileImage style={gameInfoStyles.playerImage} src={winner.imageUrl} alt="player profile" />
            <span style={gameInfoStyles.winnerRowInnerMargin}>{i + 1}</span>
            <span style={gameInfoStyles.winnerRowInnerMargin} role="img" aria-label="star">
              {i === 0 ? '🤩' : '⭐'}
            </span>
            <span style={gameInfoStyles.winnerRowInnerMargin}>{winner.name}</span>
          </div>
        ))}
      </GameInfoStateContainer>
    );
  }
  // still running tab
  return (
    <GameInfoStateContainer>
      <RowContainer>
        <Text>Premier: {game.numberOfPrizes}</Text>
        {gameStateLowerCase === 'not started' && <Text>Solgte lodd: {numberOfTickets}</Text>}
        {gameStateLowerCase === 'started' && <Text>Lodd igjen: {ticketsLeft}</Text>}
      </RowContainer>
      {gamePlayers.map((p, i) => (
        <PlayerWrapper key={i} style={p.tickets.current > 0 ? bgActive : bgLost}>
          <PlayerProfileImage src={p.imageUrl} />
          <Tickets tickets={p.tickets} />
          <PlayerName>{p.name}</PlayerName>
        </PlayerWrapper>
      ))}
    </GameInfoStateContainer>
  );
};

GameInfoState.propTypes = {
  gameRunner: PropTypes.object.isRequired,
  game: PropTypes.object.isRequired,
  allPlayers: PropTypes.array.isRequired
};

const GameInfo = ({
  title, gameRunner, game, allPlayers
}) =>
  (
    <GameInfoState
      gameRunner={gameRunner}
      game={game}
      allPlayers={allPlayers}
    />
  );

GameInfo.propTypes = {
  title: PropTypes.string.isRequired,
  gameRunner: PropTypes.object.isRequired,
  game: PropTypes.object.isRequired,
  allPlayers: PropTypes.array.isRequired
};

export default GameInfo;
