import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { RemoteData } from 'rf-comp';
import styled from 'styled-components';
import * as a from './actions';

const BalanceContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Balance = ({
  groupId, balanceState, canShowTransactions = true, userUid, ...props
}) => {
  useEffect(() => {
    props.addPlayerBalanceListener(groupId, userUid);
    return () => {
      props.removePlayerBalanceListener(groupId, userUid);
    };
  }, [userUid]);

  return (
    <BalanceContainer>
      <RemoteData type={balanceState}
        loading={() => <span>Henter...</span>}
        failure={err => <span>Feil under henting: {err.message}</span>}
        success={balance => <p> {`Saldo: ${balance.amount} kr`}</p>}
      />
      {canShowTransactions}
    </BalanceContainer>
  );
};

Balance.propTypes = {
  userUid: PropTypes.string,
  groupId: PropTypes.string.isRequired,
  addPlayerBalanceListener: PropTypes.func.isRequired,
  removePlayerBalanceListener: PropTypes.func.isRequired,
  balanceState: PropTypes.object.isRequired,
  canShowTransactions: PropTypes.bool,
};

const mapStateToProps = ({ balance }) => ({
  balanceState: balance.balanceState
});

const mapDispatchToProps = dispatch => ({
  addPlayerBalanceListener: a.addPlayerBalanceListener(dispatch),
  removePlayerBalanceListener: a.removePlayerBalanceListener(dispatch)
});

export default {
  Balance: connect(mapStateToProps, mapDispatchToProps)(Balance),
};
