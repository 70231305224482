const hashmapToArray = successCallback =>
  snapshot => {
    const hashmap = snapshot.val();

    successCallback(Object.keys(hashmap || {}).map(
      key => ({
        ...hashmap[key],
        uid: key
      })
    ));
  };

export default hashmapToArray;
