import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, createGlobalStyle } from 'styled-components';

const theme = {
  width: {
    content: '95vw'
  },
  font: {
    text: {
      sm: '0.7rem',
      md: '1.0rem',
      lg: '1.2rem'
    },
    heading: {
      sm: '1.8rem',
      md: '2.4rem',
      lg: '3rem'
    }
  },
  spacing: {
    xs: '0.25rem',
    sm: '0.5rem',
    md: '0.75rem',
    lg: '1rem',
    xl: '1.25rem',
    xxl: '1.5rem'
  },
  color: {
    primary: '#ddd',
    light: '#eee',
    grey: '#808080',
    background: {
      primary: '#262626',
      secondary: '#3b3b3b'
    }
  }
};

const GlobalStyle = createGlobalStyle`
  html{
    font-size: 16px;/*for using REM units*/
    font-size: calc(0.5vw + 1rem);
  }

  body {
    font-family: 'Roboto';
    font-weight: 400;
    line-height: 1.3;
    background: ${theme.color.background.primary};
    color: ${theme.color.primary};
  }

  html, body {
    width: 100vw;
    height: 100vh;
  }
`;

const Theme = ({ children }) => (
  <>
    <GlobalStyle />
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  </>
);

Theme.propTypes = {
  children: PropTypes.element.isRequired
};

export default Theme;
