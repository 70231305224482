import Two from 'twojs-ts';

// Set start angle of the first wedge to straight up
const commonStartAngle = (Math.PI * 2) * (3/4);

export const getNumTickets = players => players
  .map(p => parseInt(p.tickets))
  .reduce((a, b) => a + b, 0);

const accumulatedTicketSum = array => array.reduce((acc, current, index) => {
  const prev = acc.length ? acc[index-1] : 0;
  acc.push(prev + Number(current.tickets));
  return acc;
}, []);

const accumulatedAngleSum = array => array.reduce((acc, current, index) => {
  const prev = acc.length ? acc[index-1] : commonStartAngle;
  acc.push(prev + Number(current));
  return acc;
}, []);

export const updatePlayers = (inPlayers, selectedTicketIndex) => {
  const accSums = accumulatedTicketSum(inPlayers);
  const selectedPlayerIndex =
    accSums.findIndex(ticketsSum => ticketsSum > selectedTicketIndex);
  const currentPlayer = inPlayers[selectedPlayerIndex] || null;
  if (!currentPlayer) {
    return inPlayers;
  }
  if (currentPlayer.tickets > 1) {
    return inPlayers.map((player, i) =>
      i === selectedPlayerIndex
        ? ({ ...player, tickets: currentPlayer.tickets - 1 })
        : player);
  }

  return inPlayers.filter((player, i) => i !== selectedPlayerIndex);
};

const createWedgeBackground = (startAngle, endAngle, color, parameters, two) => {
  const arc = two.makeArcSegment(
    0,
    0,
    0,
    parameters.wheelRadius,
    startAngle,
    endAngle,
  );
  arc.fill = color;
  arc.stroke = '#034f84';
  arc.linewidth = 1;
  return arc;
};

const createWedgeText = (startTextAngle, endTextAngle, name, parameters, ratios) => {
  const textAngle = startTextAngle + (endTextAngle - startTextAngle) / 2.0;
  const text = new Two.Text(
    name,
    Math.cos(textAngle) * (parameters.wheelRadius / 2.0),
    Math.sin(textAngle) * (parameters.wheelRadius / 2.0)
  );
  text.rotation = textAngle;
  text.fill = '#FFFFFF';
  text.size = parameters.wheelRadius * ratios.textSize;
  return text;
};

const createWedgeBar = (barAngle, parameters, two) => {
  const circle = two.makeCircle(
    Math.cos(barAngle)*(parameters.wheelRadius),
    Math.sin(barAngle)*(parameters.wheelRadius),
    5
  );
  circle.fill = '#f7cac9';
  circle.stroke = '#034f84';
  circle.linewidth = 1;
  return circle;
};

export const getWheelElements = (players, parameters, ratios, two) => {
  const numTickets = getNumTickets(players);
  const wedgeAngle = (Math.PI * 2.0)/numTickets;

  const wheelElements = {
    arcs: [],
    bars: [],
    names: []
  };

  const wedgeAngles = Array(numTickets).fill(wedgeAngle);
  const accWedgeAngles = accumulatedAngleSum(wedgeAngles);
  const accSums = accumulatedTicketSum(players);

  wheelElements.arcs = accWedgeAngles.reduce((arcs, current, index) => {
    const startAngle = arcs.length ? accWedgeAngles[index-1] : commonStartAngle;
    const playerIndex =
      accSums.findIndex(ticketsSum => ticketsSum > index);
    arcs.push(createWedgeBackground(
      startAngle,
      current,
      players[playerIndex].color,
      parameters,
      two
    ));
    return arcs;
  }, []);

  wheelElements.bars = accWedgeAngles.reduce((bars, current, index) => {
    bars.push(createWedgeBar(current, parameters, two));
    return bars;
  }, []);

  wheelElements.names = players.map((player, index) => {
    const startAngle = index > 0 ? accWedgeAngles[accSums[index-1] - 1] : commonStartAngle;
    const endAngle = accWedgeAngles[accSums[index] - 1];
    return createWedgeText(startAngle, endAngle, player.playerName, parameters, ratios);
  });
  return wheelElements;
};

export const drawWheel = (wheelElements, players, two) => {
  const numTickets = getNumTickets(players);
  const wheelSubgroupGroup = two.makeGroup();

  for (let i = 0; i < numTickets; i +=1) {
    wheelSubgroupGroup.add(wheelElements.arcs[i]);
  }
  for (let i = 0; i < numTickets; i +=1) {
    wheelSubgroupGroup.add(wheelElements.bars[i]);
  }
  for (let i = 0; i < players.length; i +=1) {
    wheelSubgroupGroup.add(wheelElements.names[i]);
  }
  return wheelSubgroupGroup;
};

export const getCurrentWedgeSize = players =>
  (Math.PI * 2.0)/(getNumTickets(players));

export const getNextWedgeSize = players =>
  (Math.PI * 2.0)/(getNumTickets(players)-1);

function drawTickerCircle(parameters, ratios, two) {
  const tickerCircle = two.makeArcSegment(
    0,
    -parameters.wheelRadius*(1.0 + ratios.tickerOuterRadius),
    parameters.wheelRadius * ratios.tickerInnerRadius,
    parameters.wheelRadius * ratios.tickerOuterRadius,
    0,
    2 * Math.PI
  );
  tickerCircle.noStroke();
  tickerCircle.fill = '#C0C0C0';
  return tickerCircle;
}

const getCoordOnTickerCircle = (tickerAngle, tickerRadius, { cx, cy }) =>
  ({
    x: cx + tickerRadius * Math.cos(tickerAngle),
    y: cy + tickerRadius * Math.sin(tickerAngle),
  });

const drawTickerArrow = (tickerCenter, parameters, ratios, two) => {
  const { x, y } = tickerCenter;

  const angleLeft = Math.PI/2 + parameters.tickerAngle/2;
  const angleRight = Math.PI/2 - parameters.tickerAngle/2;
  const tickerRadius = parameters.wheelRadius * ratios.tickerOuterRadius;
  const tickerLength = tickerRadius*ratios.tickerLength;

  const pointA = getCoordOnTickerCircle(angleLeft, tickerRadius, { cx: x, cy: y });
  const pointB = getCoordOnTickerCircle(Math.PI/2, tickerRadius, {
    cx: x,
    cy: y,
  });
  const pointC = getCoordOnTickerCircle(angleRight, tickerRadius, {
    cx: x,
    cy: y,
  });
  const pointD = {
    x,
    y: y + tickerLength
  };
  const path = two.makePath(
    pointA.x,
    pointA.y,
    pointB.x,
    pointB.y,
    pointC.x,
    pointC.y,
    pointD.x,
    pointD.y
  );
  path.noStroke();
  path.fill = '#C0C0C0';

  return path;
};

export const drawTicker = (parameters, ratios, two) => {
  const tickerCircle = drawTickerCircle(parameters, ratios, two);
  const circleCenter = tickerCircle.translation;
  const tickerArrow = drawTickerArrow(circleCenter, parameters, ratios, two);
  const tickerGroup = two.makeGroup(tickerCircle, tickerArrow);
  tickerGroup.translation.set(two.width / 2, two.height / 2);
  return tickerGroup;
};

