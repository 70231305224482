export const HOME = '/';
export const GAME = '/game/:id';
export const NEW_GAME = '/games/new/:groupId';
export const LOGIN = '/login';
export const REGISTER = '/register';
export const PROFILE = '/profile';
export const GROUPS = '/groups';
export const NEW_GROUP = '/groups/new';
export const RESET_PASSWORD = '/reset-password';
export const DASHBOARD = '/dashboard';
export const GROUPS_EDIT = '/groups/edit/:id';
export const QUIT = '/quit';
export const GROUP_INVITE = '/groups/invite/:id';
export const GROUP_INFO = '/groups/info/:id';
export const REGISTER_PAYMENT = '/balance/registerpayment/:groupId';
export const EDIT_GAME = '/game/edit/:id';
export const AUTHORIZATIONS = '/groups/authorizations/:groupId/:playerId';
