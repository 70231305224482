import { Just, Nothing } from 'monet';

const valid = val => {
  if (val instanceof Array) {
    return val.length > 0;
  }
  if (typeof val === 'object' && val !== null) {
    return true;
  }
  return !!val;
};

const toMaybe = val =>
  valid(val)
    ? Just(val)
    : Nothing();

export default toMaybe;
