import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Page, H1, H3, Text
} from 'rf-ui';
import { RemoteData } from 'rf-comp';
import GameItem from './GameItem';
import * as a from '../games/actions';
import { getPlayerGroups } from './actions';

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DisplayGames = ({ games, authUser, groups }) => {
  const today = new Date();
  const futureGames = games
    .filter(x => x.startTime > today)
    .sort((x, y) => (x.startTime > y.startTime ? 1: (y.startTime > x.startTime) ? -1 : 0));

  const historicGames = games
    .filter(x => x.startTime < today)
    .sort((x, y) => (x.startTime > y.startTime ? -1: (y.startTime > x.startTime) ? 1 : 0));

  return (
    <>
      <RowContainer>
        <H3>Aktive spill</H3>
      </RowContainer>
      {(futureGames.length <= 0)
        ? (
          <Text>Du har ingen spill som venter</Text>
        )
        : futureGames.map(game => (
          <GameItem key={game.uid} game={game} groups={groups} authUser={authUser} />
        ))}
      <H3>Avsluttede spill</H3>
      {(historicGames.length > 0) &&
        historicGames.map(game => (
          <GameItem key={game.uid}
          game={game}
          groups={groups}
          authUser={authUser}
          complete />
        ))}
    </>
  );
};

DisplayGames.propTypes = {
  games: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired,
  authUser: PropTypes.object.isRequired,
};

const mapCombineStates = (gamesState, playerGroupsState) =>
  gamesState.flatMap(games =>
    playerGroupsState.map(groups => ({
      games,
      groups
    })));

const DashboardPage = ({
  authUser, playerGroupsState, gamesState, ...props
}) => {
  useEffect(() => {
    props.fetchPlayerGames(authUser.uid);
    props.fetchPlayerGroups(authUser.uid);
    props.resetCurrentGame();
  }, []);
  return (
    <Page>
      <H1>Lykkehjulet</H1>
      <RemoteData type={mapCombineStates(gamesState, playerGroupsState)}
        loading={() => <span>Henter...</span>}
        failure={err => <span>Feil under henting: {err}</span>}
        success={({ games, groups }) =>
          <DisplayGames games={games} groups={groups} authUser={authUser} />}
      />
    </Page>
  );
};

DashboardPage.propTypes = {
  authUser: PropTypes.object,
  gamesState: PropTypes.object.isRequired,
  fetchPlayerGames: PropTypes.func.isRequired,
  resetCurrentGame: PropTypes.func.isRequired,
  fetchPlayerGroups: PropTypes.func.isRequired,
  playerGroupsState: PropTypes.object.isRequired,
};

const mapStateToProps = ({ session, games, playerGroups }) => ({
  authUser: session.authUser,
  gamesState: games.gamesState,
  playerGroupsState: playerGroups.playerGroupsState,
});

const mapDispatchToProps = dispatch => ({
  fetchPlayerGames: a.fetchGames(dispatch),
  fetchPlayerGroups: getPlayerGroups(dispatch),
  resetCurrentGame: a.resetCurrentGame(dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardPage);
