/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { RemoteData } from 'rf-comp';
import { get, getColorFromName, isAuthorized } from 'rf-lib';
import {
  Button,
  Page,
  TextLarge,
  WheelOfFortune as WheelOfFortuneAPI,
  WheelOfTickets
} from 'rf-ui';

import { useParams } from 'react-router-dom';
import * as urls from 'rf-lib/urls';
import Tickets from './Tickets';
import * as a from './actions';
import * as store from '../../store/actions';
import GameInfo from './GameInfo';

const GameContainer = styled.div`
  display: flex;  
  flex-direction: row ;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const TicketsAndInfoContainer = styled.div`
  display: flex;  
  flex: 2;
  flex-direction: column ;
  justify-content: flex-start;
`;

const WheelContainer = styled.div`
  display: flex;  
  flex: 5;
  flex-direction: column ;
  justify-content: center;
  align-items: center;
`;

const PageContent = styled.div`
  display: flex; 
  width: 100%; 
  flex-direction: column;
  justify-content: space-between;
`;

const HeaderRow = styled.div`
  display: flex;  
  flex-direction: row ;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${get('spacingXs')};
  padding-left: ${get('spacingMd')};
  padding-right: ${get('spacingMd')};
  background: #2E4053;
`;

const GameInfoStateWrapper = styled.div`
  display: flex;
  text-transform: uppercase;
  `;

const GameHeader = ({ game, gameRunner }) => (
  <HeaderRow>
    <TextLarge>{game.gameName}</TextLarge>
    <GameInfoStateWrapper>
      {gameRunner.gameState ? gameRunner.gameState : '-'}
    </GameInfoStateWrapper>
    {new Date(game.startTime).toLocaleString()}
  </HeaderRow>
);

GameHeader.propTypes = {
  game: PropTypes.object.isRequired,
  gameRunner: PropTypes.object.isRequired,
};

function wakeUp(count = 1) {
  if (count > 10) {
    console.log('Wake-up failed too many times. Giving up...');
    return false;
  }
  console.log('Wake-up #', count);
  fetch(`${urls.pingBackend}`)
    .then((response, dispatch) => {
      console.log('Ping response:', response);
      if (response.ok) {
        console.log('Wake-up success');
      } else {
        console.log('Wake-up failed:', response.error);
        setTimeout(() => {
          wakeUp(count + 1);
        }, 500);
      }
      return response;
    })
    .catch(err => {
      console.log('Caught ping exception', err);
      setTimeout(() => {
        wakeUp(count + 1);
      }, 500);
    });
  return true;
}

const Game = ({ ...props }) => {
  const { id } = useParams();

  const onAnimationFinished = roundNumber => {
    props.setCurrentAnimation(roundNumber);
  };

  useEffect(
    () => {
      props.fetchAllPlayers();
    },
    []
  );

  useEffect(
    () => {
      const currentRound = props.gameRunner.lastShownAnimation + 1;

      const playerId = props.gameRunner.ticketsLost
        ? props.gameRunner.ticketsLost[currentRound-1]
        : undefined;

      if (playerId && !props.gameRunner.isSpinning) {
        props.setIsSpinning(true);
        WheelOfFortuneAPI.spinWheel(playerId, currentRound);
      }
    },
    [props.gameRunner.remainingPlayers, props.gameRunner.isSpinning]
  );

  useEffect(
    () => {
      props.addGameListener(id);
      props.addGameRunnerListener(id);
      props.addTicketsListener(id);
      return () => {
        props.removeGameListener(id);
        props.removeGameRunnerListener(id);
        props.removeTicketsListener(id);
      };
    },
    []
  );

  return (
    <Page>
      <RemoteData type={props.currentGame}
        loading={() => <span>Henter spill...</span>}
        failure={err => <span>Feil under henting: {err}</span>}
        success={game => (
          <PageContent>
            <GameHeader game={game} gameRunner={props.gameRunner} />
            <GameContainer>
              <TicketsAndInfoContainer>
                {(props.gameRunner.gameState === 'Not started') &&
                  (
                    <Tickets
                      gameId={id}
                      currentGame={game}
                      playerId={props.authUser.uid}
                      ticketCount={props.tickets[props.authUser.uid]}
                      handleBuy={props.handleBuy}
                      handleSell={props.handleSell} />
                  )}

                {(props.gameRunner.gameState === 'Not started' &&
                  isAuthorized('gameMaster', game.groupID, props.authUser) &&
                  wakeUp()) &&
                  (
                  <Button title="Start spill" onClick={() => props.startGame(id)} />
                  )}

                <RemoteData type={props.playersRemoteData}
                  failure={err => <span>Feil under henting av spillere: {err}</span>}
                  success={players =>
                    (
                      <GameInfo
                        title={game.gameName}
                        gameRunner={props.gameRunner}
                        game={game}
                        allPlayers={players} />
                    )}
                />
              </TicketsAndInfoContainer>

              <WheelContainer id="wheelContainer">
                <RemoteData type={props.playersRemoteData}
                  failure={err => <span>Feil under henting av spillere: {err}</span>}
                  success={players => (
                    <Wheel
                    allPlayers={players}
                    remainingPlayers={props.gameRunner.gameState === 'Not started'? game.playersWithTickets : props.gameRunner.remainingPlayers}
                    onAnimationFinished={onAnimationFinished}
                    state={props.gameRunner.gameState} />
                  )}
                />
              </WheelContainer>

            </GameContainer>
          </PageContent>
        )}
      />
    </Page>
  );
};

Game.propTypes = {
  addGameListener: PropTypes.func.isRequired,
  addGameRunnerListener: PropTypes.func.isRequired,
  addTicketsListener: PropTypes.func.isRequired,
  fetchAllPlayers: PropTypes.func.isRequired,
  handleBuy: PropTypes.func.isRequired,
  handleSell: PropTypes.func.isRequired,
  removeGameListener: PropTypes.func.isRequired,
  removeGameRunnerListener: PropTypes.func.isRequired,
  removeTicketsListener: PropTypes.func.isRequired,
  setCurrentAnimation: PropTypes.func.isRequired,
  setIsSpinning: PropTypes.func.isRequired,
  startGame: PropTypes.func.isRequired,
  authUser: PropTypes.object.isRequired,
  currentGame: PropTypes.object.isRequired,
  gameRunner: PropTypes.object.isRequired,
  playersRemoteData: PropTypes.object.isRequired,
  tickets: PropTypes.object.isRequired,
};

const getPlayer = (player, allPlayers) => {
  const foundPlayer = allPlayers.find(x => x.uid === player.playerId);
  return foundPlayer;
};

const mapPlayers = (allPlayers, remainingPlayers) => {
  const gamePlayers = remainingPlayers.map(remainingPlayer => {
    const player = getPlayer(remainingPlayer, allPlayers);

    return {
      ...remainingPlayer,
      playerName: player.displayName || '<Unknown>',
      color: player.color || getColorFromName(player.displayName),
    };
  });

  return gamePlayers;
};

const Wheel = ({
  allPlayers, remainingPlayers, onAnimationFinished, state
}) => {
  const gamePlayers = mapPlayers(allPlayers, remainingPlayers);
  if (state === 'Not started') {
    return WheelOfTickets(gamePlayers);
  }
  console.log(`Wheel updated with players ${gamePlayers.length}`);
  return WheelOfFortuneAPI.WheelOfFortune(gamePlayers, onAnimationFinished);
};

const mapStateToProps = ({ session, games }) => ({
  authUser: session.authUser,
  gameRunner: games.gameRunnerState,
  tickets: games.ticketsState,
  currentGame: games.currentGameState,
  playersRemoteData: session.playersRemoteData,
});

const mapDispatchToProps = dispatch => ({
  startGame: a.startGame(dispatch),
  fetchAllPlayers: store.fetchAllPlayers(dispatch),
  setCurrentAnimation: a.setCurrentAnimation(dispatch),
  setIsSpinning: a.setIsSpinning(dispatch),
  addGameListener: a.addGameListener(dispatch),
  removeGameListener: a.removeGameListener(dispatch),
  addGameRunnerListener: a.addGameRunnerListener(dispatch),
  removeGameRunnerListener: a.removeGameRunnerListener(dispatch),
  addTicketsListener: a.addTicketsListener(dispatch),
  removeTicketsListener: a.removeTicketsListener(dispatch),
  handleBuy: a.handleBuy(dispatch),
  handleSell: a.handleSell(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Game);
