import styled from 'styled-components';
import { get } from 'rf-lib';

const Bar = styled.div`  
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${get('colorBackgroundSecondary')};
  padding: ${get('spacingSm')};
  border-radius: ${get('spacingSm')};
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
`;

export default Bar;
