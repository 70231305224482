import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RemoteData } from 'rf-comp';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { get } from 'rf-lib';
import { Formik, Form } from 'formik';
import { CirclePicker } from 'react-color';
import * as Yup from 'yup';
import {
  FormField,
  Page, H1, Button, Text, TextSmall, ImageUpload, TransactionList
} from 'rf-ui';
import * as paths from 'rf-lib/routePaths';
import BalanceContainer from '../balance/Balance';
import * as a from '../../store/actions';
import * as g from '../playergroups/viewPlayerGroups/actions';
import * as f from '../balance/actions';

const filesizeRestriction = 10.0;

const LinksContainer = styled.div`
  margin-top: ${get('spacingMd')};
  display: flex;
  flex-direction: column;
  align-items: center;
  a:first-child {
    margin-bottom: ${get('spacingMd')};
  }
`;

const Container = styled.div`
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  grid-template-columns: 2fr 2fr;
  grid-column-gap: 10px;
  grid-gap: 20px;
  align-items: top;
  margin-top: ${get('spacingMd')};
`;

const TContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 70%;
  min-width:600;
  flex-wrap: wrap;
  grid-template-columns: 2fr 2fr;
  grid-column-gap: 10px;
  grid-gap: 20px;
  align-items: top;
  margin-top: ${get('spacingMd')};
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  min-width:600;
  padding: ${get('spacingMd')};
  justify-content: flex-start;
`;

const FramedColumn = styled(Column)`
  border: 1px solid rgba(255, 255, 255, 0.5);
`;

const SContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: column;
  flex-grow: 1;
  max-width:400;
  margin: ${get('spacingSm')} 0 ${get('spacingMd')} 0;
`;

const Select = styled.select`
  display: flex;
  font-size: 16px;
  border-radius: 3px;
  padding: 0.6rem;
  height: auto;
`;

const TransactionBox = ({
  authUser, groups, ...props
}) => {
  const [groupId, setGroupId] = useState(groups[0].id);

  useEffect(
    () => {
      if (groups) {
        props.fetchPlayerTransactions(groupId, authUser.uid);
        props.addPlayerBalanceListener(groupId, authUser.uid);
      }
    }, []
  );

  const handleGroupChange = event => {
    setGroupId(event.target.value);
    props.removePlayerBalanceListener(event.target.value, authUser.uid);
    props.addPlayerBalanceListener(event.target.value, authUser.uid);
    props.resetPlayerTransactionState();
    props.fetchPlayerTransactions(event.target.value, authUser.uid);
  };

  return (
    <div>
      <TContainer>
        <SContainer>
          <p>Vennligst velg gruppe</p>
          <Select name="groupId" onChange={handleGroupChange} {...props}>
            {groups.map((item, key) => (
              <option key={key} value={item.id}>
                {item.groupName}
              </option>
            ))}
          </Select>
        </SContainer>
        <BalanceContainer.Balance groupId={groupId} userUid={authUser.uid} />
      </TContainer>
      <RemoteData
        type={props.transactionState}
        loading={() => <span>Henter...</span>}
        failure={err => <span>Feil: {err.message}</span>}
        success={balance => (
          <TransactionList
            transactions={balance}
            totalTransactionsShown={20}
          />
        )}
      />
    </div>
  );
};

TransactionBox.propTypes = {
  authUser: PropTypes.object,
  groups: PropTypes.array,
  transactionState: PropTypes.object.isRequired,
  fetchPlayerTransactions: PropTypes.func,
  resetPlayerTransactionState: PropTypes.func,
  addPlayerBalanceListener: PropTypes.func,
  removePlayerBalanceListener: PropTypes.func,
};

const ProfilePage = ({
  authUser, updatePlayer, failure, ...props
}) => {
  const [profileImage, setImageAsFile] = useState('');
  const [imgValidationMsg, setImgValidationMsg] = useState('');
  const [color, setColor] = useState(authUser.color || '');

  const history = useHistory();

  useEffect(
    () => {
      props.getPlayerGroups(authUser.uid);
    }, []
  );

  const handleImageAsFile = file => {
    setImageAsFile(file);
  };

  const handleColorChangeComplete = newColor => {
    setColor(newColor.hex);
  };

  const handleUpdate = values => {
    const filesize = (profileImage.size/1024/1024).toFixed(4);
    if (filesize > filesizeRestriction) {
      setImgValidationMsg(`Bildet kan ikke være større enn ${filesizeRestriction}MB`);
    } else {
      setImgValidationMsg('');
      const playerCandidate = {
        uid: authUser.uid,
        email: values.email,
        displayName: values.displayName,
        image: profileImage,
        color,
      };
      updatePlayer(playerCandidate)
        .then(() => history.goBack())
        .catch(err => { });
    }
  };

  return (
    <Page>
      <Container>
        <H1 style={{ float: 'left', flexGrow: 1 }}>Rediger profil for {authUser.displayName}</H1>
      </Container>
      <Formik
        initialValues={{ email: authUser.email, displayName: authUser.displayName }}
        validationSchema={Yup.object({
          displayName: Yup.string()
            .min(3, 'Brukernavn må være minst tre bokstaver langt')
            .max(15, 'Brukernavnet kan ha maksimalt 15 tegn')
            .required('Brukernavn må være fylt ut')
        })}
        onSubmit={values => {
          handleUpdate(values);
        }}
      >
        <Form style={{ display: 'inline-flex', flexDirection: 'column', width: '100%' }}>
          <Container>
            <FramedColumn>
              <FormField
                name="email"
                disabled
                title="E-post"
                value={authUser.email}
              />
              <FormField
                autoFocus
                name="displayName"
                title="Brukernavn"
              />
              <Text>Velg farge til hjulet</Text>
              <CirclePicker
                width="100%"
                color={color}
                circleSize={42}
                onChangeComplete={handleColorChangeComplete}
              />
            </FramedColumn>

            <Column>
              <ImageUpload title="Velg profilbilde" url={authUser.imageUrl} onImage={file => handleImageAsFile(file)} />
              <TextSmall>Filen kan ikke være større enn {filesizeRestriction}MB</TextSmall>
              <TextSmall>Filen må være av formatet png eller jpeg</TextSmall>
            </Column>

          </Container>

          <Container>
            <Button title="Avbryt" type="button" onClick={() => history.push(paths.DASHBOARD)} />
            <Button title="Lagre" type="submit" />
            <LinksContainer>
              <Link to="/reset-password">Endre passord?</Link>
            </LinksContainer>
          </Container>
          <br /> {imgValidationMsg}
          <br /> {failure}
        </Form>
      </Formik>
      <Container>
        <H1>Mine Transaksjoner</H1>
      </Container>
      <TContainer>
        <RemoteData
              type={props.playerGroupsState}
              loading={() => <span>Henter...</span>}
              failure={err => <span>Feil: {err.message}</span>}
              success={groups => (
                <TransactionBox
                authUser={authUser}
                groups={groups}
                transactionState={props.transactionState}
                fetchPlayerTransactions={props.fetchPlayerTransactions}
                resetPlayerTransactionState={props.resetPlayerTransactionState}
                addPlayerBalanceListener={props.addPlayerBalanceListener}
                removePlayerBalanceListener={props.removePlayerBalanceListener}
                />
              )}
              />
      </TContainer>
    </Page>
  );
};

ProfilePage.propTypes = {
  updatePlayer: PropTypes.func.isRequired,
  authUser: PropTypes.object,
  failure: PropTypes.string,
  playerGroupsState: PropTypes.object,
  getPlayerGroups: PropTypes.func,
  transactionState: PropTypes.object.isRequired,
  fetchPlayerTransactions: PropTypes.func,
  resetPlayerTransactionState: PropTypes.func,
  addPlayerBalanceListener: PropTypes.func,
  removePlayerBalanceListener: PropTypes.func,
};

const mapStateToProps = ({ session, playerGroups, balance }) => ({
  authUser: session.authUser,
  failure: session.failure,
  playerGroupsState: playerGroups.playerGroupsState,
  transactionState: balance.transactionState,
  balanceState: balance.balanceState,
});

const mapDispatchToProps = dispatch => ({
  updatePlayer: player => a.updatePlayer(dispatch, player),
  getPlayerGroups: g.getPlayerGroups(dispatch),
  fetchPlayerTransactions: f.fetchPlayerTransactions(dispatch),
  resetPlayerTransactionState: f.resetPlayerTransactionState(dispatch),
  addPlayerBalanceListener: f.addPlayerBalanceListener(dispatch),
  removePlayerBalanceListener: f.removePlayerBalanceListener(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfilePage);
