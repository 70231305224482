import {
  pipe, split, map, toLower
} from 'ramda';

// Takes a string like 'helloPizzaMan' and returns ['hello', 'pizza', 'man']
const splitWords =
  pipe(
    split(/(?=[A-Z])/),
    map(toLower)
  );

export default splitWords;
