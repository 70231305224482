import { handleActions } from 'redux-actions';
import { RemoteData } from 'rf-lib';
import * as t from './constants';

const defaultState = {
  registerState: RemoteData.NotAsked
};

const reducer = handleActions({
  [t.CREATE_USER]: (state, action) => ({
    ...state, registerState: RemoteData.Loading
  }),

  [t.CREATE_USER_FAILURE]: (state, action) => ({
    ...state, registerState: RemoteData.Failure(action.payload)
  }),

  [t.CREATE_USER_SUCCESS]: (state, action) => ({
    ...state, registerState: RemoteData.Success(null)
  }),
}, defaultState);

export default reducer;
