/* eslint-disable no-console */
import firebase from '../firebase/firebase';
import * as players from './players';

const REACT_APP_CONFIRMATION_EMAIL_REDIRECT = `${window.location.origin.toString()}`;

const auth = () => firebase.auth;
const playerGroupsRef = uid => firebase.db.ref(`playerGroups/${uid}`);

export const sendEmailVerification = () =>
  auth().currentUser.sendEmailVerification({
    url: REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
  });

export const create = (email, password, displayName) =>
  auth().createUserWithEmailAndPassword(email, password)
    .then(authUser =>
      players
        .set({
          uid: authUser.user.uid,
          image: null,
          displayName,
          email,
        }))
    .then(sendEmailVerification);

export const signIn = (email, password) =>
  auth().signInWithEmailAndPassword(email, password);

export const signOut = () =>
  auth().signOut();

export const sendPasswordResetEmail = email =>
  auth().sendPasswordResetEmail(email);

export const updatePassword = password =>
  auth().currentUser.updatePassword(password);

export const mapUserRoles = groups => {
  const groupRoles = gr => {
    if (typeof gr !== 'object') {
      return ({ admin: false, accountant: false, gameMaster: false });
    }

    return Object.keys(gr || {})
      .map(key => ({
        role: key,
        authorized: gr[key]
      }));
  };

  return Object.keys(groups || {})
    .map(key => ({
      groupId: key,
      roles: groupRoles(groups[key])
    }));
};

const getUserRoles = userId => {
  const result = playerGroupsRef(userId).once('value')
    .then(snapshot => {
      const groups = snapshot.val();
      return mapUserRoles(groups);
    });

  return result;
};

export const onAuthStateChanged = (successCallback, failureCallback) =>
  auth().onAuthStateChanged(authUser => {
    if (authUser) {
      players.get(authUser.uid)
        .then(playersSnap => {
          getUserRoles(authUser.uid)
            .then(roles => {
              const dbPlayer = playersSnap.val();

              // merge auth user and player
              const user = {
                uid: authUser.uid,
                email: authUser.email,
                emailVerified: authUser.emailVerified,
                providerData: authUser.providerData,
                ...dbPlayer,
                roles,
              };

              successCallback(user);
            });
        });
    } else {
      failureCallback();
    }
  });

export const onAuthorizationsChanged = (playerId, successCallback, failureCallback) =>
  playerGroupsRef(playerId).on('value', successCallback, failureCallback);
