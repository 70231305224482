import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  Button, Text, TextSmall
} from 'rf-ui';
import { get } from 'rf-lib';
import BalanceContainer from '../balance/Balance';

const TicketContainer = styled.div`
  border: 1px solid lightgrey;
  width: 100%;
  margin-bottom: 1rem;
  padding: 0.5rem;
`;

const ControlsContainer = styled.div`
  display: flex;  
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 200;
  margin-left: 0.5rem;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  background: ${props => props.background ? get('colorBackgroundSecondary') : ''};
`;

const Col = styled.div`
  flex: ${props => props.size};
  margin: 0.4rem;
  align-items: ${props => props.alignItems};
  justify-content: ${props => props.justifyContent};
`;

const Tickets = ({
  gameId,
  currentGame,
  playerId,
  ticketCount,
  handleBuy,
  handleSell
}) => {
  const {
    maxTicketsPerPlayer, ticketPrice, maxTickets, groupID, tickets
  } = currentGame;

  let numberOfTickets = 0;
  if (tickets) {
    numberOfTickets = Object.values(tickets)
      .reduce(
        (accumulator, currentValue) => accumulator + currentValue, 0
      );
  }

  return (
    <TicketContainer>
      <Row>
        <Col size={3}>
          <Text>{`Lodd á kr. ${ticketPrice}`}</Text>
          <Text>{`Maks. ${maxTicketsPerPlayer} lodd`}</Text>
        </Col>
        <Col size={2}>
          <ControlsContainer>
            <Button title="-" disabled={!ticketCount || ticketCount <= 0} onClick={() => handleSell(gameId, playerId)} />
            <Text>
              {ticketCount || 0}
            </Text>
            <Button title="+"
              disabled={(ticketCount >= maxTicketsPerPlayer) ||
                ((numberOfTickets !== undefined) && (numberOfTickets >= maxTickets))}
              onClick={() => handleBuy(gameId, playerId)} />
          </ControlsContainer>
        </Col>
      </Row>
      <Row background>
        <Col alignItems="flex-start" justifyContent="flex-start">
          <TextSmall style={{ color: numberOfTickets === currentGame.maxTickets ? 'red' : '#ddd' }}>
            Totalt solgt: {`${numberOfTickets}/${currentGame.maxTickets}`}
          </TextSmall>
        </Col>
        <Col alignItems="flex-end" justifyContent="flex-end">
          <BalanceContainer.Balance
            groupId={groupID}
            userUid={playerId}
            canShowTransactions={false}
          />
        </Col>
      </Row>
    </TicketContainer>
  );
};

Tickets.propTypes = {
  gameId: PropTypes.string.isRequired,
  playerId: PropTypes.string.isRequired,
  ticketCount: PropTypes.number,
  handleBuy: PropTypes.func.isRequired,
  handleSell: PropTypes.func.isRequired,
  currentGame: PropTypes.object.isRequired,
};

export default Tickets;
