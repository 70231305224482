/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { RemoteData, NotAuthorized } from 'rf-comp';
import {
  FormField,
  H1,
  Button,
  ImageUpload
} from 'rf-ui';
import { isAuthorized } from 'rf-lib';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import * as a from './actions';

const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

/* ************************************
***** UPDATE GROUP / DELETE IMAGE *****
************************************ */
const GroupUpdate = ({
  history, authUser, group, groupID, updateGroup, deleteGroupImage
}) => {
  const [error, setError] = useState('');
  const [groupImage, setImage] = useState('');
  const [groupImageURL, setGroupImageURL] = useState(group.imageUrl);

  const groupSchema = Yup.object({
    groupName: Yup.string()
      .trim()
      .max(40, 'Gruppenavnet er for langt')
      .min(5, 'Gruppenavnet er for kort, må være mellom 5-40 bokstaver')
      .required('Required'),
    groupDescription: Yup.string()
      .trim()
      .min(5, 'Gruppebeskrivelsen er for kort, må være mellom 5-50 bokstaver')
      .max(50, 'Gruppebeskrivelsen er for lang, må være mellom 5-50 bokstaver')
      .required('Required')
  });

  const handleImageAsFile = file => {
    setImage(file);
  };

  return (
    <GroupContainer>
      <h1>Rediger din gruppe her:</h1>
      <Formik enableReinitialize
        initialValues={{
          groupName: group.groupName,
          groupDescription: group.groupDescription
        }}
        validationSchema={groupSchema}
        onSubmit={(groupCandidate, { setSubmitting }) => {
          updateGroup(groupID, groupCandidate, groupImage)
            .then((history.push('/groups/')))
            .catch(err => setError(err.message));
          setSubmitting(false);
        }}
      >
        <Form>
          <FormField
            name="groupName"
            title="Gruppenavn"
          />
          <FormField
            name="groupDescription"
            title="Beskrivelse"
          />
          <ImageUpload title="Velg bilde"
            url={groupImageURL}
            onImage={file => handleImageAsFile(file)} />
          <Button title="Lagre" type="submit" />
        </Form>
      </Formik>
      {error}
      <Button onClick={() => deleteGroupImage(groupID).then(setGroupImageURL(''))} title="Slett bilde" />
    </GroupContainer>
  );
};

GroupUpdate.propTypes = {
  history: PropTypes.object,
  group: PropTypes.object.isRequired,
  groupID: PropTypes.string.isRequired,
  authUser: PropTypes.object.isRequired,
  updateGroup: PropTypes.func.isRequired,
  deleteGroupImage: PropTypes.func.isRequired,
};

/* *********************
***** DELETE GROUP *****
********************* */
const DeleteGroup = ({
  history, groupID, deleteGroup
}) =>
  <Button onClick={() => deleteGroup(groupID).then((history.push('/groups/')))} title="Slett gruppen" />;

DeleteGroup.propTypes = {
  history: PropTypes.object,
  groupID: PropTypes.string.isRequired,
  deleteGroup: PropTypes.func.isRequired,
};

/* *******************************
***** EDIT PLAYER GROUP MAIN *****
******************************* */
const EditPlayerGroup = ({ authUser, ...props }) => {
  const { id } = useParams();
  const history = useHistory();

  if (!isAuthorized('admin', id, authUser)) {
    return <NotAuthorized message="You are not authorized to edit this player group" />;
  }

  useEffect(() => {
    props.fetchGroupDetails(id);
  }, []);

  return (
    <div>
      <H1>Rediger Gruppe</H1>
      <RemoteData
        type={props.groupDetailsState}
        loading={() => <span>Henter gruppedetaljer...</span>}
        failure={err => <span>Feil: {err.message}</span>}
        success={group => (
          <>
            <GroupUpdate
              history={history}
              authUser={authUser}
              group={group}
              groupID={id}
              updateGroup={props.updateGroup}
              deleteGroupImage={props.deleteGroupImage}
            />
            <DeleteGroup
              history={history}
              groupID={id}
              deleteGroup={props.deleteGroup}
            />
          </>
        )}
        />
      <Button onClick={history.goBack} title="Tilbake" />
    </div>
  );
};

EditPlayerGroup.propTypes = {
  authUser: PropTypes.object,
  groupDetailsState: PropTypes.object.isRequired,
  fetchGroupDetails: PropTypes.func.isRequired,
  updateGroup: PropTypes.func.isRequired,
  deleteGroup: PropTypes.func.isRequired,
  deleteGroupImage: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  fetchGroupDetails: a.getGroupDetails(dispatch),
  updateGroup: a.updateGroup(dispatch),
  deleteGroup: a.deleteGroup(dispatch),
  deleteGroupImage: a.deleteGroupImage(dispatch),
});

const mapStateToProps = ({ session, playerGroupInfo }) => ({
  authUser: session.authUser,
  groupDetailsState: playerGroupInfo.groupDetailsState,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPlayerGroup);
