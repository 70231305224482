import React from 'react';
import { connect } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Logo, Button } from 'rf-ui';
import * as p from 'rf-lib/routePaths';
import * as a from '../../store/actions';

const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
`;

const Right = styled.div`
  width: 100%;
  padding-top: 1.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Heading = styled.div`
  height: auto;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1.2rem;
`;

const Title = styled.h1`
  font-size: 4.25rem;
  font-weight: bold;
  margin: 0.7em;
  margin-top: 6.25rem;
  color: white;
  padding: 1.2rem;

  @media only screen and (max-width: 600px)  {
    font-size: 2rem;
    margin-top: 0;
  }
`;

const Text = styled.p`
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    width: 60%;
    margin-bottom: 1rem;
  }

  a:last-child {
    margin-top: 1rem;
  }
`;

const WelcomeNewUser = ({ displayName, email }) => (
  <Text>Velkommen, {displayName}!
    Du må bekrefte epost-adressen din før du kan gå videre.
    Sjekk innboksen til {email}!
  </Text>
);

WelcomeNewUser.propTypes = {
  displayName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired
};

const HomePage = ({
  authUser, signOut, ...props
}) => {
  const { displayName, email, emailVerified } =
    authUser || { displayName: '', email: '', emailVerified: false };
  const isRegistered = authUser;
  const isLoggedIn = isRegistered && emailVerified;
  const history = useHistory();

  if (isLoggedIn) {
    return (<Redirect push to={p.DASHBOARD} />);
  }

  return (
    <Container>
      <Right>
        <Heading>
          <Logo />
          <Title>Lykkehjulet</Title>
        </Heading>
        <Body>
          <Text>
            Ta med dine venner eller kollegaer og start et Lykkehjul.
            Velg mellom gratis eller betalt spill og se hvem som vinner!!!!!!!!!!!!!!
          </Text>
          {isRegistered && <WelcomeNewUser displayName={displayName} email={email} />}
          <br />
          <Button title="Logg inn" onClick={() => history.push(p.LOGIN)} />
        </Body>
      </Right>
    </Container>
  );
};

HomePage.propTypes = {
  signOut: PropTypes.func.isRequired,
  authUser: PropTypes.object
};

const mapStateToProps = ({ session }) => ({
  authUser: session.authUser
});

const mapDispatchToProps = dispatch => ({
  signOut: () => a.signOutUser(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomePage);
